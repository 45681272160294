/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompanyCreate } from '../models/CompanyCreate';
import type { CompanyDetail } from '../models/CompanyDetail';
import type { PagedCompanyDetail } from '../models/PagedCompanyDetail';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CompaniesService {

    /**
     * Create Company
     * @param requestBody 
     * @returns CompanyDetail OK
     * @throws ApiError
     */
    public static createCompany(
requestBody: CompanyCreate,
): CancelablePromise<CompanyDetail> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/companies/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get Company
     * @param companyId 
     * @returns CompanyDetail OK
     * @throws ApiError
     */
    public static getCompany(
companyId: string,
): CancelablePromise<CompanyDetail> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/companies/{company_id}',
            path: {
                'company_id': companyId,
            },
        });
    }

    /**
     * Update Company
     * @param companyId 
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static updateCompany(
companyId: string,
requestBody: CompanyCreate,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/companies/{company_id}',
            path: {
                'company_id': companyId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete Company
     * @param companyId 
     * @returns any OK
     * @throws ApiError
     */
    public static deleteCompany(
companyId: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/companies/{company_id}',
            path: {
                'company_id': companyId,
            },
        });
    }

    /**
     * List Companies
     * @param order 
     * @param q 
     * @param page 
     * @returns PagedCompanyDetail OK
     * @throws ApiError
     */
    public static listCompanies(
order?: string,
q?: string,
page: number = 1,
): CancelablePromise<PagedCompanyDetail> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/companies',
            query: {
                'order': order,
                'q': q,
                'page': page,
            },
        });
    }

}
