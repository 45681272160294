import * as React from "react";
import {FunctionComponent, ReactNode} from "react";
import {withTooltip} from "@consta/uikit/withTooltip";
import {Text} from "@consta/uikit/Text";

type Props = {
    content?: string;
    children?: ReactNode;
    textProps?: any;
}

export const TextWithPopover: FunctionComponent<Props> = ({
                                                              content,
                                                              children,
                                                              textProps = {
                                                                  size: 's'
                                                              }
                                                          }) => {
    const TextWithPopover = withTooltip({
        content: content ?? '',
        direction: 'downRight'
    })(Text);

    return (
        <TextWithPopover {...textProps}>{children}</TextWithPopover>
    );
}