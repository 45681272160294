import * as React from 'react';
import {FunctionComponent, useEffect, useState} from 'react';
import {Layout} from "@consta/uikit/Layout";
import styles from './Transport.module.css';
import {Table, TableColumn, TableRow} from "@consta/uikit/Table";
import {Badge} from "@consta/uikit/Badge";
import {Text} from "@consta/uikit/Text";
import {
    displaySmenaType,
    displayTaskStatus,
    // displayTaskStatusStyle,
    formatDate,
    formatDateTime,
    secondsToTimedeltaString
} from "../../utils/helpers";
import {
    ApiError, PagedTransportWithCurrentSmenaTask,
    SmenaTransportInline,
    TaskInline,
    Transport as TransportSchema,
    TransportCreate,
    TransportsService,
    // TransportType,
    TransportTypeCreate, TransportWithCurrentSmenaTask,
    // TransportWithSmenaTasks
} from "../../api";
import {Button} from "@consta/uikit/Button";
import {useNavigate, useSearchParams} from "react-router-dom";
import {ProtectedRouter, PublicRouter} from "../../Routers";
import {IconAdd} from "@consta/uikit/IconAdd";
// import {Modal} from "@consta/uikit/Modal";
import {TextField} from "@consta/uikit/TextField";
import {Toast} from "../../components/Toast";
import {Sidebar} from "@consta/uikit/Sidebar";
import {TaskView} from "../../components/TaskView";
import {TransportView} from "../../components/TransportView";
import {TextWithPopover} from "../../components/TextWithPopover";
import {Loader} from "@consta/uikit/Loader";
import {IconClose} from "@consta/uikit/IconClose";
import {IconHome} from "@consta/uikit/IconHome";
import {ListPage} from "../../components/ListPage";
import {Switch} from "@consta/uikit/Switch";
import {FilterPageEnum} from "../../utils/enum";
import {Popup} from "../../components/Popup";
import {useDebounce} from "@consta/uikit/useDebounce";
// import {useMediaQuery} from "react-responsive";
import {useAuth} from '../../contexts/AuthContext';
import styled from 'styled-components';

const GreenVariable = styled.span`
      color: green;
    `;
    const BlackVariable = styled.span`
      color: black;
    `;
    const RedVariable = styled.span`
      color: red;
    `;

type TransportRow = TableRow & {
    name?: string;
    status?: boolean;
    current_task?: TaskInline;
    current_smena?: SmenaTransportInline;
};

type TransportTypeRow = TableRow & {
    name: string;
    type?: string;
    status?: boolean;
    rows?: TransportRow[];
};

function dateWithTooltip(time?: string) {
    if (!time) return '-';

    return (
        <TextWithPopover
            textProps={{size: 's'}}
            content={formatDateTime(time) ?? undefined}
        >{formatDateTime(time, '-', {timeStyle: 'short'})}</TextWithPopover>
    );
}

const Transport: FunctionComponent = () => {
    const useauth = useAuth().user.email
    // const isDesktop = useMediaQuery({minWidth: 1024}); // ширина экрана для десктопной версии
    const [searchParams, setSearchParams] = useSearchParams();
    // const [errors, setErrors] = useState<string | undefined>(undefined);
    // const [submitted, setSubmitted] = useState<boolean>(false);
    const [alert, setAlert] = useState<any>({text: '', status: 'success'});
    const [loading, setLoading] = useState<boolean>(false);
    const [taskSidebar, setTaskSidebar] = useState<string | undefined>(undefined);
    const transportTypeCreateInitial = {
        name: '',
    };
    const [transportTypeCreate, setTransportTypeCreate] = useState<TransportTypeCreate>(transportTypeCreateInitial);
    const transportCreateInitial = {
        type_id: '',
        reg_number: ''
    };
    const [transportCreate, setTransportCreate] = useState<TransportCreate>(transportCreateInitial);
    const [selectedTransport] = useState<TransportSchema | undefined>(undefined);
    const [transportTypes, setTransportTypes] = useState<TransportTypeRow[]>([]);
    const [isTransportCreateModalOpen, setIsTransportCreateModalOpen] = useState<boolean>(false);
    const [isTransportTypeCreateModalOpen, setIsTransportTypeCreateModalOpen] = useState<boolean>(false);
    const navigate = useNavigate();
    const [page, setPage] = useState<number>(searchParams.get(FilterPageEnum.Page) ? parseInt(searchParams.get(FilterPageEnum.Page)!) : 1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [onlyOnline, setOnlyOnline] = useState<boolean | undefined>(searchParams.get(FilterPageEnum.Online) === 'false' ? false : true);
    const [searchFieldValue, setSearchFieldValue] = useState<string | null>(searchParams.get(FilterPageEnum.Search) ?? null);
    const [searchByName, setSearchByName] = useState<string | null>(searchFieldValue);
    const [sortBy, setSortBy] = useState<string | undefined>(searchParams.get(FilterPageEnum.SortBy) ?? undefined);
    const debounceSetSearchValue = useDebounce(setSearchByName, 300);
    // const [time, setTime] = useState(new Date());

    const [totalOnline, setTotalOnline] = useState<number>();
    const [totalInLine, setTotalInLine] = useState<number>();

    useEffect(() => {
        document.title = 'Транспорт | SMA';
    }, []);

    useEffect(() => {
        debounceSetSearchValue(searchFieldValue);
    }, [searchFieldValue]);

    useEffect(() => {
        if (searchByName) {
            searchParams.set(FilterPageEnum.Search, searchByName)
        } else {
            searchParams.delete(FilterPageEnum.Search)
        }
        setSearchParams(searchParams);
    }, [searchByName]);

    useEffect(() => {
        if (onlyOnline) {
            searchParams.set(FilterPageEnum.Online, String(onlyOnline));
        } else {
            searchParams.delete(FilterPageEnum.Online);
        }
        setSearchParams(searchParams);
    }, [onlyOnline]);


    useEffect(() => {
        const fetchData = () => {
            setLoading(true);
            TransportsService.listTransportsWithStatus(
                onlyOnline,
                searchByName ?? undefined,
                sortBy,
                page
            )
                .then((data: PagedTransportWithCurrentSmenaTask) => {
                    if (data.items) {
                        const reGroup = Object.values(
                            data.items.reduce(
                                (previousValue: any, currentValue: TransportWithCurrentSmenaTask) => {
                                    if (!previousValue[currentValue.type.id])
                                        previousValue[currentValue.type.id] = {
                                            id: currentValue.type.id,
                                            name: currentValue.type.name,
                                            rows: [],
                                        };
                                    previousValue[currentValue.type.id].rows.push({
                                        id: currentValue.id, reg_number: currentValue.reg_number,
                                        created_at: currentValue.created_at,
                                        updated_at: currentValue.updated_at,
                                        base_location: currentValue.base_location,
                                        // location: currentValue.location,
                                        current_smena: currentValue.current_smena,
                                        current_task: currentValue.current_task,
                                        wells: currentValue.wells,
                                        worktype: currentValue.worktype,
                                    });
                                    return previousValue;
                                },
                                {}
                            )
                        );
                        // @ts-ignore
                        setTransportTypes([...reGroup]);
                        setTotalPages(Math.max(page, Math.ceil(data.count / 200)));


                    }
                })
                // .catch((err: ApiError) => setErrors(err.message))
                .finally(() => setLoading(false));
        };
        fetchData();
        const interval = setInterval(() => {
            fetchData();
        }, 60000);

        return () => clearInterval(interval);
    }, [onlyOnline, searchByName, sortBy, page]);

    const errorHandler = (err: ApiError) => {
        if (err.status === 404) {
            navigate(PublicRouter.NOT_FOUND);
            return;
        }
        let text = JSON.stringify(err);
        if (typeof err?.body?.detail === "string") {
            text = err.body.detail;
        }
        if (Array.isArray(err?.body?.detail)) {
            text = err.body.detail.map((item: any) => item.msg).join('\n');
        }
        setAlert({status: 'alert', text})
    };

    const submitTransportType = () => {
        TransportsService.createTransportType(transportTypeCreate)
            .then((data: any) => {
                setAlert({status: 'success', text: `Техника ${data.name} успешно создана!`});
            })
            .then(() => {
                setIsTransportTypeCreateModalOpen(false);
                setTransportTypeCreate(transportTypeCreateInitial);
                // setSubmitted(true);

            })
            .catch(errorHandler);
    }

    const submitTransport = () => {
        TransportsService.createTransport(transportCreate)
            .then((data: any) => {
                setAlert({status: 'success', text: `Техника ${data.reg_number} успешно создана!`});
            })
            .then(() => {
                setIsTransportCreateModalOpen(false);
                setTransportCreate(transportCreateInitial);
                // setSubmitted(true);
            })
            .catch(errorHandler);
    }

    function handleSort(value: any) {
        if (value) {
            const order = (value.sortOrder === 'desc' ? '-' : '') + value.sortingBy;
            searchParams.set(FilterPageEnum.SortBy, order);
            setSortBy(order);
        } else {
            searchParams.delete(FilterPageEnum.SortBy);
            setSortBy(undefined);
        }
        setSearchParams(searchParams);
    }

    const breadCrumbs = [
        {
            label: 'Главная',
            href: ProtectedRouter.SUBMISSION,
            icon: IconHome
        },
        {
            label: 'Транспорт',
        },
    ];
    //считаем тс на линии
    useEffect(() => {
        // @ts-ignore
        const filteredElements = transportTypes.flatMap(transportType => transportType.rows).filter(row => row?.current_smena?.delay === null);
        const totalTS = transportTypes.flatMap(transportType => transportType.rows).filter(row => row?.current_smena?.delay !== null);
        console.log(filteredElements?.length)
        setTotalOnline(filteredElements?.length)
        setTotalInLine(totalTS.length)
    }, [loading]);


    const techLabel = (online: number | undefined, inline: number | undefined) => {
        return (
            <>
                <BlackVariable>Техника (</BlackVariable>
                <GreenVariable> {online}</GreenVariable>
                <BlackVariable>/</BlackVariable>
                <RedVariable>{inline}</RedVariable>
                <BlackVariable>)</BlackVariable>
            </>
        );
    };

    const columns: TableColumn<any>[] = [
        {
            title: 'id',
            accessor: 'id',
            hidden: true,
        },
        {
            title: techLabel(totalOnline, totalInLine),
            accessor: 'reg_number',
            sortable: true,
            width: 200,
            renderCell: (row) => row.rows ? (
                <Layout className={styles.TransportType__Cell}>
                    <Text size='s'>{row.name}</Text>
                    <Badge status='normal' label={`${row.rows.length}`} view='stroked' form='round' size='s'/>
                </Layout>
            ) : (
                <Layout className={styles.Layout__Center}>
                    <Badge
                        minified
                        status={row.current_smena ? row.current_smena.online ? 'success' : 'warning' : 'error'}
                        label={row.current_smena ? row.current_smena.online ? `На линии\n${row.current_smena.person.fio}\n${formatDate(row.current_smena.date)} (${displaySmenaType(row.current_smena.type)})` : 'Не на линии' : 'Нет смены'}
                    />
                    <Text size='s'
                        // className={styles.Text__Link}
                        // view='link'
                        // onClick={() => {
                        //     setSelectedTransport({
                        //         id: row.id, type: {id: row.type!, name: row.name}, reg_number: row?.reg_number!
                        //     });
                        //     setIsTransportCreateModalOpen(true);
                        // }}
                    >{row.reg_number}</Text>
                </Layout>)
        },
        {
            title: 'Текущая смена',
            columns: [
                {
                    title: 'Начало',
                    accessor: 'smena__fact_start',
                    sortable: true,
                    width: 90,
                    renderCell: (row) => (
                        <Text size='xs'>{formatDateTime(row.current_smena?.fact_start)}</Text>)
                },
                {
                    title: 'Конец',
                    accessor: 'smena__fact_end',
                    sortable: true,
                    width: 90,
                    renderCell: (row) => (
                        <Layout direction='column'>
                            <Text size='xs'>{formatDateTime(row.current_smena?.fact_end)}</Text>
                            {
                                row.current_smena?.delay &&
                                <TextWithPopover content='Задержка завершения смены'
                                                 textProps={{
                                                     size: 'xs',
                                                     view: 'alert'
                                                 }}
                                >
                                    {`+ ${secondsToTimedeltaString(row.current_smena?.delay, false)}`}
                                </TextWithPopover>
                            }
                        </Layout>
                    )
                },
            ]
        },
        {
            title: 'Тек.задача',
            columns: [
                {
                    title: '№',
                    accessor: 'tasks__name',
                    sortable: true,
                    // width: 110,
                    renderCell: (row) => {
                        if (!row.rows && row.current_task?.name) {
                            return (
                                <Layout className={styles.Layout__Center}>
                                    {/*<Badge minified*/}
                                    {/*       status={displayTaskStatusStyle(row.current_task?.status)}*/}
                                    {/*       label={displayTaskStatus(row.current_task?.status)}*/}
                                    {/*/>*/}
                                    <Text size='xs' className={styles.Text__Link}
                                          view='link'
                                          onClick={() => setTaskSidebar(row.current_task?.id)}
                                    >{row.current_task?.name}</Text>
                                </Layout>
                            )
                        }
                    }
                },
                {
                    title: 'Статус',
                    accessor: 'current_task.status',
                    width: 90,
                    renderCell: (row) => {
                        if (!row.current_task) {
                            return '';
                        }

                        return (
                            // <Badge
                            //     size='s'
                            //     status={displayTaskStatusStyle(row.current_task?.status)}
                            //     label={displayTaskStatus(row.current_task?.status)}
                            // />
                            <Text size='xs'>{displayTaskStatus(row.current_task?.status)}</Text>
                        );
                    }
                },
                {
                    title: 'Описание',
                    accessor: 'current_task.about',
                    width: 170,
                    renderCell: (row) => row.rows ? '' : (
                        <Text size='xs'>{row.current_task?.about}</Text>
                    )
                },
                {
                    title: 'Создана',
                    accessor: 'tasks__created_at',
                    sortable: true,
                    width: 90,
                    renderCell: (row) => row.rows ? '' : (
                        <Text size='xs'>{formatDateTime(row.current_task?.created_at)}</Text>
                    )
                },
            ],
        },
        {
            title: 'Движение',
            columns: [
                {
                    title: 'План',
                    // width: 75,
                    accessor: 'current_task.move_start',
                    renderCell: (row) => {
                        if (row.rows) return '';
                        return dateWithTooltip(row.current_task?.move_start);
                    },
                },
                {
                    title: 'начало',
                    width: 85,
                    accessor: 'current_task.fact_move_start',
                    renderCell: (row) => {
                        if (row.rows) return '';
                        if (row.current_task?.move_start_delay && row.current_task?.fact_move_start) {
                            return (
                                <TextWithPopover
                                    content={`${formatDateTime(row.current_task?.fact_move_start)}\nЗадержка начала движения:\n+ ${secondsToTimedeltaString(row.current_task?.move_start_delay, false)}`}
                                    textProps={{
                                        size: 's',
                                        view: 'alert'
                                    }}
                                >
                                    {formatDateTime(row.current_task?.fact_move_start,
                                        '-',
                                        {timeStyle: 'short'})}
                                </TextWithPopover>
                            )
                        }
                        if (row.current_task?.move_start_delay) {
                            return (
                                <TextWithPopover content='Задержка начала движения'
                                                 textProps={{
                                                     size: 's',
                                                     view: 'alert'
                                                 }}
                                >
                                    {`+ ${secondsToTimedeltaString(row.current_task?.move_start_delay, false)}`}
                                </TextWithPopover>
                            )
                        }
                        return dateWithTooltip(row.current_task?.fact_move_start);
                    },
                },
                {
                    title: 'прибытие',
                    width: 75,
                    accessor: 'current_task.fact_move_end',
                    renderCell: (row) => {
                        if (row.rows) return '';
                        return dateWithTooltip(row.current_task?.fact_move_end);
                    },
                },
            ],
        },
        {
            title: 'Начало работ',
            columns: [
                {
                    title: 'План',
                    // width: 75,
                    accessor: 'current_task.start',
                    renderCell: (row) => {
                        if (row.rows) return '';
                        return dateWithTooltip(row.current_task?.start);
                    },
                },
                {
                    title: 'Факт',
                    // width: 75,
                    accessor: 'current_task.fact_start',
                    renderCell: (row) => {
                        if (row.rows) return '';
                        if (row.current_task?.start_delay && row.current_task?.fact_start) {
                            return (
                                <TextWithPopover
                                    content={`${formatDateTime(row.current_task?.fact_start)}\nЗадержка начала работы:\n+ ${secondsToTimedeltaString(row.current_task?.start_delay, false)}`}
                                    textProps={{
                                        size: 's',
                                        view: 'alert'
                                    }}
                                >
                                    {formatDateTime(row.current_task?.fact_start,
                                        '-',
                                        {timeStyle: 'short'})}
                                </TextWithPopover>
                            )
                        }
                        if (row.current_task?.start_delay) {
                            return (
                                <TextWithPopover content='Задержка начала движения'
                                                 textProps={{
                                                     size: 's',
                                                     view: 'alert'
                                                 }}
                                >
                                    {`+ ${secondsToTimedeltaString(row.current_task?.start_delay, false)}`}
                                </TextWithPopover>
                            )
                        }
                        return dateWithTooltip(row.current_task?.fact_start);
                    },
                },
            ],
        },
        {
            title: 'Завершение работ',
            columns: [
                {
                    title: 'План',
                    // width: 75,
                    accessor: 'current_task.end',
                    sortable: true,
                    renderCell: (row) => {
                        if (row.rows) return '';
                        return dateWithTooltip(row.current_task?.end);
                    },
                },
                {
                    title: 'Факт',
                    // width: 75,
                    accessor: 'current_task.fact_end',
                    sortable: true,
                    renderCell: (row) => {
                        if (row.rows) return '';
                        if (row.current_task?.end_delay && row.current_task?.fact_end) {
                            return (
                                <TextWithPopover
                                    content={`${formatDateTime(row.current_task?.fact_end)}\nЗадержка завершения работ:\n+ ${secondsToTimedeltaString(row.current_task?.end_delay, false)}`}
                                    textProps={{
                                        size: 's',
                                        view: 'alert'
                                    }}
                                >
                                    {formatDateTime(row.current_task?.fact_end,
                                        '-',
                                        {timeStyle: 'short'})}
                                </TextWithPopover>
                            )
                        }
                        if (row.current_task?.end_delay) {
                            return (
                                <TextWithPopover content='Задержка начала движения'
                                                 textProps={{
                                                     size: 's',
                                                     view: 'alert'
                                                 }}
                                >
                                    {`+ ${secondsToTimedeltaString(row.current_task?.end_delay, false)}`}
                                </TextWithPopover>
                            )
                        }
                        return dateWithTooltip(row.current_task?.fact_end);
                    },
                },
            ],
        },
    ];


    // const filters = [
    //     {
    //         id: 'type',
    //         name: 'Техника: ',
    //         field: 'type',
    //         filterer: (
    //             cellValue: TransportType,
    //             filterValues: Array<{ value: string; name: string }>,
    //         ) => {
    //             return filterValues.some(
    //                 (filterValue) => filterValue && filterValue.value === cellValue.name,
    //             );
    //         },
    //         component: {
    //             name: TableTextFilter,
    //             props: {
    //                 withSearch: true,
    //                 items: [
    //                     {name: 'АЦН', value: 'АЦН'},
    //                 ],
    //             },
    //         },
    //     },
    // ];

    return (
        <ListPage breadCrumbs={breadCrumbs} page={page} totalPages={totalPages} setPage={setPage}>
            <Layout className={styles.Actions}>
                <Layout className={styles.Actions__Filters} direction='row'>
                    <TextField placeholder="Найти..."
                               onChange={({value}) => setSearchFieldValue(value)}
                               value={searchFieldValue}
                               withClearButton
                    />
                    <Switch checked={onlyOnline}
                            onChange={() => setOnlyOnline(!onlyOnline)}
                            label='На линии'
                    />
                </Layout>
                <Layout className={styles.Actions__Buttons__Container}>
                    {/*<Button className={styles.Actions__Button}*/}
                    {/*        iconRight={IconAdd}*/}
                    {/*        view='secondary'*/}
                    {/*        label='Добавить технику'*/}
                    {/*        onClick={() => setIsTransportTypeCreateModalOpen(true)}*/}
                    {/*/>*/}
                    {useauth !== 'utt1@cud-hnt.ru' && (
                        <Button className={styles.Actions__Button}
                                iconRight={IconAdd}
                                view='secondary'
                                label='Добавить ед. техники'
                                onClick={() => setIsTransportCreateModalOpen(true)}
                        />
                    )}

                </Layout>
            </Layout>

            <Table className={styles.Table}
                   columns={columns}
                   rows={transportTypes}
                   size='s'
                // filters={filters}
                   stickyHeader={true}
                   onSortBy={handleSort}
                   borderBetweenColumns={true}
                   zebraStriped='even'
                   defaultExpandAll={true}
                   isResizable={true}
                   emptyRowsPlaceholder={(loading ? <Loader className={styles.Loader}/> : undefined)}
            />

            <Popup className={styles.Modal__Container}
                   isOpen={isTransportTypeCreateModalOpen}
                   onClose={() => setIsTransportTypeCreateModalOpen(false)}
            >
                <Layout className={styles.Modal__Content}
                        direction='column'
                >
                    <Layout direction='column' className={styles.Modal__Fieldset}>
                        <Text as='h1' className={styles.Modal__Header}>Добавить тип техники</Text>
                        <TextField label='Название'
                                   placeholder='Введите название'
                                   onChange={({value}) => setTransportTypeCreate({
                                       ...transportTypeCreate,
                                       name: value!
                                   })}
                                   value={transportTypeCreate.name}
                        />
                        <TextField label='Описание'
                                   placeholder='Введите описание'
                                   type='textarea'
                                   rows={3}
                                   onChange={({value}) => setTransportTypeCreate({
                                       ...transportTypeCreate,
                                       about: value!
                                   })}
                                   value={transportTypeCreate.about}
                        />
                    </Layout>
                    <Layout className={styles.Modal__Footer}>
                        <Button className={styles.Actions__Button}
                                type='reset'
                                view='secondary'
                                label='Отмена'
                                onClick={() => setIsTransportTypeCreateModalOpen(false)}
                        />
                        <Button
                            label='Сохранить'
                            onClick={() => submitTransportType()}
                        />
                    </Layout>
                </Layout>
            </Popup>
            <Popup className={styles.Modal__Container}
                   isOpen={isTransportCreateModalOpen}
                   onClose={() => setIsTransportCreateModalOpen(false)}
            >
                {
                    selectedTransport ? (
                        <TransportView
                            transport={selectedTransport}
                            isOpen={setIsTransportCreateModalOpen}
                        />
                    ) : (
                        <TransportView
                            transportTypes={transportTypes}
                            transportCreate={transportCreate}
                            setTransportCreate={setTransportCreate}
                            submitTransport={submitTransport}
                            isOpen={setIsTransportCreateModalOpen}
                        />
                    )
                }
            </Popup>
            <Sidebar isOpen={!!taskSidebar}
                     position='left'
                     size='2/3'
                     onEsc={() => setTaskSidebar(undefined)}
                     onClickOutside={() => setTaskSidebar(undefined)}
            >
                <Button className={styles.Popup__Close}
                        view='clear'
                        iconRight={IconClose}
                        onlyIcon
                        size='l'
                        onClick={() => setTaskSidebar(undefined)}
                />
                <TaskView task_id={taskSidebar}/>
            </Sidebar>
            <Toast alert={alert} setAlert={setAlert}/>
        </ListPage>
    );
};

export {Transport};
