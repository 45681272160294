import {FunctionComponent, useRef, useState} from "react";
import {Header as ConstaHeader, HeaderLogin, HeaderLogo, HeaderMenu, HeaderModule} from "@consta/uikit/Header";
import {Link} from "react-router-dom";
import {PublicRouter} from "../../Routers";
import styles from './Header.module.css';
import {useMenuItems} from "../../hooks/useMenuItems";
import {useAuth} from "../../contexts/AuthContext";
import {ContextMenu} from "@consta/uikit/ContextMenu";
import {Button} from "@consta/uikit/Button";
import {IconExit} from "@consta/uikit/IconExit";
import {useMediaQuery} from 'react-responsive';


export const Header: FunctionComponent = () => {
    const items = useMenuItems();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const {user, logout} = useAuth();
    const refProfile = useRef(null);
    const isDesktop = useMediaQuery({minWidth: 1024}); // ширина экрана для десктопной версии
    const profileItems: any[] = [
        {
            key: 1,
            label: 'Выйти',
            onClick: () => {
                console.log('click')
                logout();
            }
        }
    ];
    return (
        <ConstaHeader
            className={styles.header}
            leftSide={
                <>
                    <HeaderModule>
                        <HeaderLogo className={styles.header__logo}>
                            <Link to={PublicRouter.HOME} className={styles.header__logo_link}>СМАРТ сервис</Link>
                        </HeaderLogo>
                    </HeaderModule>

                    <HeaderModule indent="l">
                        <HeaderMenu items={items}/>
                    </HeaderModule>
                </>
            }
            rightSide={
                <HeaderModule className={styles.header__user_info}>
                    {isDesktop && (
                        <HeaderLogin
                            authorized={!!user}
                            personName={user.fio ?? user.email}
                            personInfo={user.company ?? ''}
                            ref={refProfile}
                            onClick={() => {
                                if (!!user) {
                                    setIsOpen(!isOpen);
                                }
                            }}
                        />
                    )}
                    <Button iconLeft={IconExit}
                            onlyIcon
                            view='clear'
                            onClick={() => logout()}
                    />
                    {isOpen && (
                        <ContextMenu
                            size='s'
                            items={profileItems}
                            isOpen={isOpen}
                            getItemLabel={(item: any) => item.label}
                            getItemKey={({key}) => key}
                            anchorRef={refProfile}
                            direction="downStartRight"
                            onClickOutside={() => setIsOpen(false)}
                            onClick={() => setIsOpen(true)}
                        />
                    )}
                </HeaderModule>
            }
        />
    );
}