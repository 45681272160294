import * as React from 'react';
import {FunctionComponent, useEffect, useState} from 'react';
import {Layout} from "@consta/uikit/Layout";
import styles from './TransportView.module.css';
import {Text} from "@consta/uikit/Text";
import {ApiError, Task, TasksService, Transport, TransportCreate} from "../../api";
import {NotFound} from "../NotFound/NotFound";
import {Select} from "@consta/uikit/Select";
import {TextField} from "@consta/uikit/TextField";
import {Button} from "@consta/uikit/Button";

type Props = {
    transport?: Transport;
    transportCreate?: TransportCreate;
    setTransportCreate?: (value: TransportCreate) => void;
    transportTypes?: any[];
    isOpen?: (a: boolean) => void;
    submitTransport?: () => void;
}

const TransportView: FunctionComponent<Props> = ({
                                                     transport,
                                                     transportCreate,
                                                     setTransportCreate,
                                                     transportTypes,
                                                     isOpen,
                                                     submitTransport
                                                 }) => {
    const transportCreateInitial = {
        type_id: '',
        reg_number: ''
    };

    if (!transport && isOpen && transportTypes && transportCreate && setTransportCreate) {
        return (
            <Layout direction='column'
                    className={styles.Modal__Content}
            >
                <Layout direction='column' className={styles.Modal__Fieldset}>
                    <Text as='h1' className={styles.Modal__Header}>Добавить единицу техники</Text>
                    <Select
                        placeholder="Выберите тип техники"
                        className={styles.Field}
                        label='Тип техники'
                        getItemKey={(item: any) => item.id}
                        getItemLabel={(item: any) => item.name}
                        value={transportTypes?.find((type) => type.id === transportCreate!.type_id)}
                        onChange={({value}) => setTransportCreate({...transportCreate, type_id: value!.id})}
                        items={transportTypes}
                    />
                    <TextField
                        onChange={({value}) => setTransportCreate({...transportCreate, reg_number: value!})}
                        value={transportCreate.reg_number}
                        type="text"
                        label="Гос.номер"
                        placeholder="а000аа00"
                        status={!transportCreate.reg_number || transportCreate.reg_number.match(/[авеклморстух]\d{3}[авеклморстух]{2}\d{2,3}/i) ? undefined : 'alert'}
                        caption={!transportCreate.reg_number || transportCreate.reg_number.match(/[авеклморстух]\d{3}[авеклморстух]{2}\d{2,3}/i) ? undefined : 'неверный формат'}
                        autoFocus
                    />
                </Layout>
                <Layout className={styles.Modal__Footer}>
                    <Button type='reset'
                            className={styles.Actions__Button}
                            view='secondary'
                            label='Отмена'
                            onClick={() => {
                                isOpen(false);
                                setTransportCreate(transportCreateInitial);
                            }}
                    />
                    <Button
                        label='Сохранить'
                        onClick={submitTransport}
                    />
                </Layout>
            </Layout>
        );
    }

    if (transport) {
        return (
            <>
                {JSON.stringify(transport)}
            </>
        );
    }

    return <></>;
};

export {TransportView};
