import React, {useEffect, useMemo} from 'react';
import {AuthService, OpenAPI, TokenObtain, User, UserDetail, UsersService} from "../api";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../hooks/useLocalStorage";
import {PublicRouter} from "../Routers";


interface AuthContextInterface {
    user: UserDetail;
    setUser: (user: UserDetail) => void;
    login: (user: TokenObtain) => any;
    logout: (callback?: VoidFunction) => void;
}

export const AuthContext = React.createContext<AuthContextInterface>(null!);

export const AuthProvider = ({children}: { children: React.ReactNode }) => {
    const [user, setUser] = useLocalStorage("user", undefined);
    const [access, setAccess] = useLocalStorage("access", undefined);
    const navigate = useNavigate();
    OpenAPI.TOKEN = localStorage.getItem('access') as string;

    function checkAuth() {
        UsersService.getMe()
            .then(setUser)
            .catch((err) => {
                if (err.status === 401) {
                    logout();
                }
                console.log(err)
            });
    }

    useEffect(() => {
        if (OpenAPI.TOKEN) {
            checkAuth();
        }
    }, [OpenAPI.TOKEN]);

    const login = async (user: TokenObtain, callback?: VoidFunction) => {
        return AuthService.authorize(user)
            .then(({access}) => {
                if (access) {
                    localStorage.setItem('access', access);
                    navigate(PublicRouter.HOME)
                }
            })
    };

    const logout = () => {
        setUser(undefined);
        localStorage.removeItem('access');
        localStorage.removeItem('user');
        navigate(PublicRouter.LOGIN, {replace: true});
    };

    const value = useMemo(
        () => ({
            user,
            setUser,
            login,
            logout
        }),
        [user]
    );
    return (<AuthContext.Provider value={value}>{children}</AuthContext.Provider>);
};

export const useAuth = () => React.useContext(AuthContext);