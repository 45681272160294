/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagedTransportWithCurrentSmenaTask } from '../models/PagedTransportWithCurrentSmenaTask';
import type { Transport } from '../models/Transport';
import type { TransportCreate } from '../models/TransportCreate';
import type { TransportType } from '../models/TransportType';
import type { TransportTypeCreate } from '../models/TransportTypeCreate';
import type { TransportWithTasks } from '../models/TransportWithTasks';
import type { TypeWithTransportWithActiveTasks } from '../models/TypeWithTransportWithActiveTasks';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TransportsService {

    /**
     * Create Transport
     * @param requestBody 
     * @returns Transport OK
     * @throws ApiError
     */
    public static createTransport(
requestBody: TransportCreate,
): CancelablePromise<Transport> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/transports',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * List Transports
     * @returns TransportWithTasks OK
     * @throws ApiError
     */
    public static listTransports(): CancelablePromise<Array<TransportWithTasks>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/transports',
        });
    }

    /**
     * Get Transport
     * @param transportId 
     * @returns Transport OK
     * @throws ApiError
     */
    public static getTransport(
transportId: string,
): CancelablePromise<Transport> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/transports/{transport_id}',
            path: {
                'transport_id': transportId,
            },
        });
    }

    /**
     * Update Transport
     * @param transportId 
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static updateTransport(
transportId: string,
requestBody: TransportCreate,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/transports/{transport_id}',
            path: {
                'transport_id': transportId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete Transport
     * @param transportId 
     * @returns any OK
     * @throws ApiError
     */
    public static deleteTransport(
transportId: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/transports/{transport_id}',
            path: {
                'transport_id': transportId,
            },
        });
    }

    /**
     * List Transports With Status
     * @param online 
     * @param q 
     * @param order 
     * @param page 
     * @returns PagedTransportWithCurrentSmenaTask OK
     * @throws ApiError
     */
    public static listTransportsWithStatus(
online?: boolean,
q?: string,
order?: string,
page: number = 1,
): CancelablePromise<PagedTransportWithCurrentSmenaTask> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/transports/with-status',
            query: {
                'online': online,
                'q': q,
                'order': order,
                'page': page,
            },
        });
    }

    /**
     * List Transports By Type
     * @returns TypeWithTransportWithActiveTasks OK
     * @throws ApiError
     */
    public static listTransportsByType(): CancelablePromise<Array<TypeWithTransportWithActiveTasks>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/transports/typed',
        });
    }

    /**
     * Create Transport Type
     * @param requestBody 
     * @returns TransportType OK
     * @throws ApiError
     */
    public static createTransportType(
requestBody: TransportTypeCreate,
): CancelablePromise<TransportType> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/transports/types',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * List Transport Types
     * @returns TransportType OK
     * @throws ApiError
     */
    public static listTransportTypes(): CancelablePromise<Array<TransportType>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/transports/types',
        });
    }

    /**
     * Get Transport Type
     * @param transportTypeId 
     * @returns TransportType OK
     * @throws ApiError
     */
    public static getTransportType(
transportTypeId: string,
): CancelablePromise<TransportType> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/transports/types/{transport_type_id}',
            path: {
                'transport_type_id': transportTypeId,
            },
        });
    }

    /**
     * Update Transport Type
     * @param transportTypeId 
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static updateTransportType(
transportTypeId: string,
requestBody: TransportTypeCreate,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/transports/types/{transport_type_id}',
            path: {
                'transport_type_id': transportTypeId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete Transport Type
     * @param transportTypeId 
     * @returns any OK
     * @throws ApiError
     */
    public static deleteTransportType(
transportTypeId: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/transports/types/{transport_type_id}',
            path: {
                'transport_type_id': transportTypeId,
            },
        });
    }

}
