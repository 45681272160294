import * as React from 'react';
import {FunctionComponent} from 'react';
import {Layout} from "@consta/uikit/Layout";
import styles from './Loading.module.css';
import {Loader} from "@consta/uikit/Loader";

const Loading: FunctionComponent = () => {
    return (
        <Layout className={styles.Content__Center}>
            <Loader/>
        </Layout>
    );
};

export {Loading};
