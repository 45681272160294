/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagedSubmissionWithTransportTypeCount } from '../models/PagedSubmissionWithTransportTypeCount';
import type { Submission } from '../models/Submission';
import type { SubmissionCreate } from '../models/SubmissionCreate';
import type { SubmissionTransportTypeInline } from '../models/SubmissionTransportTypeInline';
import type { SubmissionTransportTypeInlineUpdate } from '../models/SubmissionTransportTypeInlineUpdate';
import type { TaskCreate } from '../models/TaskCreate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SubmissionsService {

    /**
     * Create
     * @param requestBody 
     * @returns Submission OK
     * @throws ApiError
     */
    public static create(
requestBody: SubmissionCreate,
): CancelablePromise<Submission> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/submissions',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get All
     * @param status 
     * @param q 
     * @param statusComplete 
     * @param megaSearch 
     * @param page 
     * @returns PagedSubmissionWithTransportTypeCount OK
     * @throws ApiError
     */
    public static getAll(
status?: Array<string>,
q?: string,
statusComplete?: boolean,
megaSearch?: string,
page: number = 1,
): CancelablePromise<PagedSubmissionWithTransportTypeCount> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/submissions',
            query: {
                'status': status,
                'q': q,
                'status_complete': statusComplete,
                'mega_search': megaSearch,
                'page': page,
            },
        });
    }

    /**
     * Create Task
     * @param submissionTransportTypeId 
     * @param requestBody 
     * @returns SubmissionTransportTypeInline OK
     * @throws ApiError
     */
    public static createTask(
submissionTransportTypeId: string,
requestBody: TaskCreate,
): CancelablePromise<SubmissionTransportTypeInline> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/submissions/{submission_transport_type_id}/task',
            path: {
                'submission_transport_type_id': submissionTransportTypeId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get Submission
     * @param submissionId 
     * @returns Submission OK
     * @throws ApiError
     */
    public static getSubmission(
submissionId: string,
): CancelablePromise<Submission> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/submissions/{submission_id}',
            path: {
                'submission_id': submissionId,
            },
        });
    }

    /**
     * Delete Submission
     * @param submissionId 
     * @returns any OK
     * @throws ApiError
     */
    public static deleteSubmission(
submissionId: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/submissions/{submission_id}',
            path: {
                'submission_id': submissionId,
            },
        });
    }

    /**
     * Update Submission Transport Type
     * @param submissionTransportTypeId 
     * @param requestBody 
     * @returns SubmissionTransportTypeInline OK
     * @throws ApiError
     */
    public static updateSubmissionTransportType(
submissionTransportTypeId: string,
requestBody: SubmissionTransportTypeInlineUpdate,
): CancelablePromise<SubmissionTransportTypeInline> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/submissions/submission_transport_type/{submission_transport_type_id}',
            path: {
                'submission_transport_type_id': submissionTransportTypeId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Task Report
     * @returns any OK
     * @throws ApiError
     */
    public static taskReport(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/submissions/report/task/',
        });
    }

    /**
     * Smena Report
     * @returns any OK
     * @throws ApiError
     */
    public static smenaReport(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/submissions/report/smena/',
        });
    }

    /**
     * Upload
     * @param formData 
     * @returns any OK
     * @throws ApiError
     */
    public static upload(
formData: {
file: Blob;
},
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/submissions/import/',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}
