/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagedPersonWithAll } from '../models/PagedPersonWithAll';
import type { Person } from '../models/Person';
import type { PersonCreate } from '../models/PersonCreate';
import type { PersonUpdate } from '../models/PersonUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProfilesService {

    /**
     * Get Profile List
     * @returns Person OK
     * @throws ApiError
     */
    public static getProfileList(): CancelablePromise<Array<Person>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/profiles/',
        });
    }

    /**
     * Get Profile List Detail
     * @param order 
     * @param q 
     * @param page 
     * @returns PagedPersonWithAll OK
     * @throws ApiError
     */
    public static getProfileListDetail(
order?: string,
q?: string,
page: number = 1,
): CancelablePromise<PagedPersonWithAll> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/profiles/detail/',
            query: {
                'order': order,
                'q': q,
                'page': page,
            },
        });
    }

    /**
     * Get Profile
     * @param profileId 
     * @returns Person OK
     * @throws ApiError
     */
    public static getProfile(
profileId: string,
): CancelablePromise<Person> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/profiles/{profile_id}',
            path: {
                'profile_id': profileId,
            },
        });
    }

    /**
     * Delete Profile
     * @param profileId 
     * @returns any OK
     * @throws ApiError
     */
    public static deleteProfile(
profileId: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/profiles/{profile_id}',
            path: {
                'profile_id': profileId,
            },
        });
    }

    /**
     * Create Profile
     * @param requestBody 
     * @returns Person Created
     * @throws ApiError
     */
    public static createProfile(
requestBody: PersonCreate,
): CancelablePromise<Person> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/profiles/register/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update Profile
     * @param profileId 
     * @param requestBody 
     * @returns Person OK
     * @throws ApiError
     */
    public static updateProfile(
profileId: string,
requestBody: PersonUpdate,
): CancelablePromise<Person> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/profiles/update/{profile_id}',
            path: {
                'profile_id': profileId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
