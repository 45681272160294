import * as React from 'react';
import {FunctionComponent} from 'react';
import {Badge} from "@consta/uikit/Badge";

type Props = {
    status?: string;
}

const Status: FunctionComponent<Props> = ({status}) => {

    switch (status) {
        case 'CREATED':
            return (
                <Badge status='system'
                       label='Создана'
                       size='s'
                />
            )
        case 'PROCESSED':
            return (
                <Badge status='success'
                       label='Обработан'
                       size='s'
                />
            )
        case 'PROCESSEDH':
            return (
                <Badge status='success'
                       view='stroked'
                       label={'Обработан частично'}
                       size='s'
                />
            )
        case 'AWAITING':
            return (
                <Badge status='warning'
                       label={'В ожидании'}
                       size='s'
                />
            )
        case 'DECLINED':
            return (
                <Badge status='error'
                       label='Отклонена'
                       size='s'
                />
            )
        default:
            return (
                <>{status}</>
            );
    }
};

export {Status};
