import {Item} from '@consta/uikit/__internal__/src/components/Header/Menu/HeaderMenu';
import {useLocation, useNavigate} from 'react-router-dom';
import {ProtectedRouter, PublicRouter} from "../Routers";

enum Menu {
    DASHBOARD = 'Администрирование',
    SUBMISSION_CREATE = 'Новая заявка ＋',
    SUBMISSION = 'Заявки',
    TASKS = 'Задачи',
    TRANSPORT = 'Транспорт',
}

type Items = Array<Item & Required<{ href: string }>>;

const items: Items = [
    {
        label: Menu.TRANSPORT,
        href: ProtectedRouter.TRANSPORTS
    },
    {
        label: Menu.SUBMISSION,
        href: ProtectedRouter.SUBMISSION
    },
    {
        label: Menu.TASKS,
        href: ProtectedRouter.TASKS
    },
    // {
    //     label: Menu.DASHBOARD,
    //     href: ProtectedRouter.DASHBOARD
    // },

];


const useMenuItems = (): Item[] => {
    const navigate = useNavigate()
    let {pathname} = useLocation();

    return items.map((item) => {
        const {href} = item;

        if (pathname === PublicRouter.HOME) {
            pathname = ProtectedRouter.TRANSPORTS;
        }

        return {
            ...item,
            active: pathname.includes(href),
            onClick: (event) => {
                navigate(href);

                event.preventDefault();
            }
        }
    });
};

export {useMenuItems};