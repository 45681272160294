import * as React from 'react';
import {FunctionComponent, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Layout} from "@consta/uikit/Layout";
import styles from './Users.module.css';
import {UserDetail, UsersService} from "../../api";
import {Table, TableColumn} from "@consta/uikit/Table";
import {Checkbox} from "@consta/uikit/Checkbox";


const Users: FunctionComponent = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [errors, setErrors] = useState<string | undefined>(undefined);
    const [users, setUsers] = useState<UserDetail[]>([]);

    useEffect(() => {
        UsersService.getUserList()
            .then((data) => setUsers([...data]))
            .catch((err) => setErrors(err));
    }, []);

    const columns: TableColumn<any>[] = [
        {
            title: 'UUID',
            accessor: 'id',
        },
        {
            title: 'Email',
            accessor: 'email',
        },
        {
            title: 'Зарегистрирован',
            accessor: 'date_joined',
        },
        {
            title: 'Админ?',
            accessor: 'is_staff',
            renderCell: ({is_staff}) => (<Checkbox view="primary" checked={is_staff} />)
        },
    ];

    return (
        <>
            <Layout className={styles.Users}>
                <Table className={styles.Users__Table} columns={columns} rows={users}/>
            </Layout>
        </>
    );
};

export {Users};
