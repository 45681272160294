/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Brigade } from '../models/Brigade';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BrigadesService {

    /**
     * List Brigades
     * @returns Brigade OK
     * @throws ApiError
     */
    public static listBrigades(): CancelablePromise<Array<Brigade>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/brigades',
        });
    }

}
