import * as React from 'react';
import {FormEvent, FunctionComponent, useCallback, useEffect, useState} from 'react';
import {TaskCreate, TasksService} from "../../../api";
import {Form} from "../../../components/Form";
import {Informer} from "@consta/uikit/Informer";
import {IconThumbUp} from "@consta/uikit/IconThumbUp";
import {StatusInformerEnum} from "../../../utils/enum";
import {StatusInformerType} from "../../../utils/type";
import {IconAlert} from "@consta/uikit/IconAlert";
import {TextField} from "@consta/uikit/TextField";

const TaskCreateForm: FunctionComponent = () => {
    const [errors, setErrors] = useState<string | undefined>(undefined);
    const [isInformerOpen, setIsInformerOpen] = useState(false);
    const [timerId, setTimerId] = useState<NodeJS.Timeout>();
    const [statusInformerTitle, setStatusInformerTitle] = useState('Задача успешно создана!');
    const [statusInformer, setStatusInformer] = useState<StatusInformerType>(
        StatusInformerEnum.Success,
    );
    const [taskCreate, setTaskCreate] = useState<TaskCreate>({
        // location_id: '',
        wells_id: '',
        worktype_id: '',
        transport_id: '',
        about: '',
        start: '',
        expected_duration: 0
    });
    useEffect(() => {
        TasksService.createTask(taskCreate)
            .then(() => setIsInformerOpen(true))
            .catch((err) => {
                setErrors(err);
                setStatusInformer(StatusInformerEnum.Alert);
                setStatusInformerTitle(err);
            });
    }, []);


    const handleSubmit = useCallback((e: FormEvent) => {
        e.preventDefault();
        console.log('handleSubmit')

    }, []);


    return (
        <>
            <Form
                mode='create'
                label='Задача'
                onSubmit={handleSubmit}
                errors={errors}
            >
                <TextField label='ыфав'></TextField>
            </Form>
            {/*{*/}
            {/*    isInformerOpen && (*/}
            {/*        <>*/}
            {/*            <Informer*/}
            {/*                status={statusInformer}*/}
            {/*                size="s"*/}
            {/*                title={statusInformerTitle}*/}
            {/*                onClick={() => {*/}
            {/*                    setIsInformerOpen(false);*/}
            {/*                    if (timerId) clearTimeout(timerId);*/}
            {/*                }}*/}
            {/*                icon={*/}
            {/*                    statusInformer === StatusInformerEnum.Alert ? IconAlert : IconThumbUp*/}
            {/*                }*/}
            {/*            />*/}
            {/*        </>*/}
            {/*    )*/}
            {/*}*/}
        </>
    );
};

export {TaskCreateForm};
