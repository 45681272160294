import * as React from "react";
import {FunctionComponent, ReactNode} from "react";
import styles from './FieldRow.module.css';
import {Text} from "@consta/uikit/Text";
import {Layout} from "@consta/uikit/Layout";

type Props = {
    label?: string,
    children?: ReactNode;
    className?: string;
    size?: "m" | "2xs" | "xs" | "s" | "l" | "xl" | "2xl" | "3xl" | "4xl" | "5xl" | "6xl";
}

export const FieldRow: FunctionComponent<Props> = ({
                                                       label,
                                                       children,
                                                       className,
                                                       size
                                                   }) => {

    return (
        <Layout className={className ?? styles.FieldSet__Row}>
            <Text className={styles.FieldSet__Row__Label} view='secondary' size={size}>{label}</Text>
            {children}
        </Layout>
    );
}