import {Item} from '@consta/uikit/__internal__/src/components/Header/Menu/HeaderMenu';
import {useLocation, useNavigate} from 'react-router-dom';
import {ProtectedRouter, PublicRouter} from "../Routers";

enum Menu {
    PROFILES = 'Сотрудники',
    USERS = 'Пользователи',
    TG_USERS = 'Телеграм пользователи',
    COMPANIES = 'Компании',
    LOCATIONS = 'Локации',
}

type Items = Array<Item & Required<{ href: string }>>;

const items: Items = [
    {
        label: Menu.PROFILES,
        href: ProtectedRouter.PROFILES
    },
    {
        label: Menu.COMPANIES,
        href: ProtectedRouter.COMPANIES
    },
    {
        label: Menu.LOCATIONS,
        href: ProtectedRouter.LOCATIONS
    },
];


const useDashboardMenuItems = (): Item[] => {
    const navigate = useNavigate()
    let {pathname} = useLocation();

    return items.map((item) => {
        const {href} = item;

        return {
            ...item,
            active: pathname.includes(href),
            onClick: (event) => {
                navigate(href);

                event.preventDefault();
            }
        }
    });
};

export {useDashboardMenuItems};