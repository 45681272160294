export const enum StatusInformerEnum {
    Success = 'success',
    System = 'system',
    Alert = 'alert',
    Warning = 'warning',
}

export enum FilterPageEnum {
    PerPage = 'per_page',
    Page = 'page',
    SortBy = 'order',
    Search = 'q',
    Status = 'status',
    Online = 'online',
    Delay = 'delay',
    Date = 'date',
    StartDate = 'start_date',
    EndDate = 'end_date',
    StatusComplete = 'status_complete',
    MegaSearch = 'mega_search'
}
