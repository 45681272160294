import * as React from 'react';
import {FormEvent, useCallback, useState} from 'react';
import {Layout} from "@consta/uikit/Layout";
import styles from './Form.module.css';
import {Button} from "@consta/uikit/Button";
import {useNavigate} from "react-router-dom";
import {Text} from "@consta/uikit/Text";
import {IconSave} from "@consta/uikit/IconSave";
import {IconArrowLeft} from "@consta/uikit/IconArrowLeft";
import {Informer} from "@consta/uikit/Informer";
import {IconThumbUp} from "@consta/uikit/IconThumbUp";
import {IconAlert} from "@consta/uikit/IconAlert";

type Field = {
    title: string;
    accessor: string;
    readOnly?: boolean;
    hidden?: boolean;
    renderCell?: any;
}


type CreatePageProps = {
    mode: 'create' | 'edit' | 'view'
    label: string
    children: any;
    errors?: any;
    showActions?: boolean
    notification?: any;
    onSubmit: (e: FormEvent) => void;
    onClose?: (e: FormEvent) => void;
    className?: string;
}

const Form: React.FC<CreatePageProps> = ({
                                             mode,
                                             label,
                                             children,
                                             onSubmit,
    onClose,
                                             errors,
                                             notification,
                                             showActions,
                                             className
                                         }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    const handleSubmit = useCallback((e: FormEvent) => {
        e.preventDefault();
        setLoading(true);
        new Promise((resolve) => {
            onSubmit(e);
            return resolve(null);
        }).finally(() => setLoading(false));
    }, [onSubmit]);

    function getLabel(mode: 'create' | 'edit' | 'view') {
        switch (mode) {
            case "create":
                return `Добавить ${label}`
            case "edit":
                return `Изменить ${label}`
            case "view":
                return `Просмотр ${label}`
        }
    }

    return (
        <Layout className={className ?? styles.Container}>
            <Layout as='form' className={styles.Form} onSubmit={handleSubmit}>
                {
                    notification &&
                    <Informer className={styles.Alert}
                              {...notification}
                              icon={notification.status === 'success' ? IconThumbUp : IconAlert}
                    />
                }
                {
                    <Text as='h1'>{getLabel(mode)}</Text>
                }
                {children}
                {
                    errors &&
                    <Text view='alert'>{errors}</Text>
                }
                {
                    showActions && (mode === 'create' || mode === 'edit') &&
                    <Layout className={styles.Actions}>
                        <Button iconLeft={IconArrowLeft}
                                view='secondary'
                                label='Отмена'
                                type='reset'
                                onClick={onClose ? onClose : () => navigate(-1)}
                        />
                        <Button iconLeft={IconSave}
                                label='Сохранить'
                                type='submit'
                                loading={loading}
                        />
                    </Layout>
                }
            </Layout>
        </Layout>
    );
};

export {Form};
