/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Location } from '../models/Location';
import type { LocationCreate } from '../models/LocationCreate';
import type { LocationUpdate } from '../models/LocationUpdate';
import type { PagedLocationDetail } from '../models/PagedLocationDetail';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LocationsService {

    /**
     * Create Location
     * @param requestBody 
     * @returns Location OK
     * @throws ApiError
     */
    public static createLocation(
requestBody: LocationCreate,
): CancelablePromise<Location> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/locations',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * List Locations
     * @param order 
     * @param q 
     * @param page 
     * @returns PagedLocationDetail OK
     * @throws ApiError
     */
    public static listLocations(
order?: string,
q?: string,
page: number = 1,
): CancelablePromise<PagedLocationDetail> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/locations',
            query: {
                'order': order,
                'q': q,
                'page': page,
            },
        });
    }

    /**
     * Get Location
     * @param locationId 
     * @returns Location OK
     * @throws ApiError
     */
    public static getLocation(
locationId: string,
): CancelablePromise<Location> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/locations/{location_id}',
            path: {
                'location_id': locationId,
            },
        });
    }

    /**
     * Update Location
     * @param locationId 
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static updateLocation(
locationId: string,
requestBody: LocationUpdate,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/locations/{location_id}',
            path: {
                'location_id': locationId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete Location
     * @param locationId 
     * @returns any OK
     * @throws ApiError
     */
    public static deleteLocation(
locationId: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/locations/{location_id}',
            path: {
                'location_id': locationId,
            },
        });
    }

}
