enum ProtectedRouter {
    DASHBOARD = '/dashboard',

    TASKS = '/tasks',
    TASKS_CREATE = '/tasks/create',

    SUBMISSION = '/submission',
    SUBMISSION_CREATE = '/submission/create',
    SUBMISSION_PROCESS = '/submission/:submissionId/process',

    TRANSPORTS = '/transports',
    TRANSPORT = '/transports/:transportId',

    USERS = '/dashboard/users',
    TG_USERS = '/dashboard/tg-users',
    PROFILES = '/dashboard/profiles',
    COMPANIES = '/dashboard/companies',

    CREATE = 'create',
    EDIT = 'edit',
    VIEW = 'view',

    LOCATIONS = '/dashboard/locations',
    LOCATION = '/dashboard/locations/:locationId',
}

enum PublicRouter {
    HOME = '/',
    LOGIN = '/login',
    DENIED = '/error/403',
    NOT_FOUND = '/error/404',
}

export {PublicRouter, ProtectedRouter};
