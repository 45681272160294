import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {useAuth} from '../../contexts/AuthContext';
import {PublicRouter} from "../../Routers";
import {BaseLayout} from "../BaseLayout";

type ProtectedRouteType = {
    staff_only?: boolean;
    children?: JSX.Element;
}

const ProtectedRoutes: React.FC<ProtectedRouteType> = ({children, staff_only}) => {
    const {user} = useAuth();

    if (staff_only) {
        return user ? (
            user.is_staff ? (
                <BaseLayout>
                    <Outlet/>
                </BaseLayout>
            ) : (
                <Navigate to={PublicRouter.DENIED}/>
            )
        ) : (
            <Navigate to={PublicRouter.LOGIN}/>
        )
    } else {
        return user ? <BaseLayout>
            <Outlet/>
        </BaseLayout> : <Navigate to={PublicRouter.LOGIN}/>
    }
};


export {ProtectedRoutes};
