import * as React from 'react';
import {FunctionComponent} from 'react';
import {Layout} from "@consta/uikit/Layout";
import {Header} from "../Header/Header";
import styles from './BaseLayout.module.css';

type LayoutProps = {
    children: any;
}

const BaseLayout: FunctionComponent<LayoutProps> = ({children}) => {

    return (
        <>
            <Header/>
            <Layout className={styles.Container}>
                {children}
            </Layout>
        </>
    );
};

export {BaseLayout};
