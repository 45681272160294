import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import {Login} from '../pages/Login';
import {ProtectedRouter, PublicRouter} from '../Routers';
import {AuthProvider} from '../contexts/AuthContext';
import {Theme} from "@consta/uikit/Theme";
import {OpenAPI} from "../api";
import {REACT_APP_BASE_API_PATH, SITE_NAME} from "../config/config";
import {NotFound} from "./NotFound/NotFound";
import {Dashboard} from "../pages/Dashboard";
import {Users} from "../pages/Users";
import {Companies} from "../pages/Companies";
import styles from "./App.module.css";
import {useThemeToggle} from "../hooks/useThemeToggle";
import {AccessDenied} from "./AccessDenied/AccessDenied";
import {ProtectedRoutes} from "./ProtectedRoutes";
import {Profiles} from "../pages/Profiles";
import {Locations} from "../pages/Locations";
import {TaskList} from "../pages/Tasks/TaskList";
import {Transport, TransportCreateForm} from "../pages/Transport";
import {TaskCreateForm} from "../pages/Tasks/TaskCreateForm";
import {SubmissionProcess} from "../pages/Submission/SubmissionProcess";
import {SubmissionForm} from "../pages/Submission/SubmissionForm";
import {SubmissionList} from "../pages/Submission/SubmissionList";


const App = () => {
    OpenAPI.BASE = REACT_APP_BASE_API_PATH ?? '';
    document.title = `${SITE_NAME}`;
    const {theme} = useThemeToggle();

    return (
        <Theme preset={theme.value} className={styles.Page}>
            <AuthProvider>
                <Routes>
                    <Route path={PublicRouter.LOGIN} element={<Login/>}/>
                    {/*<Route path={Router.REGISTER} element={<Register/>}/>*/}
                    <Route path="/" element={<ProtectedRoutes/>}>
                        <Route path={PublicRouter.HOME} element={<Navigate to={ProtectedRouter.TRANSPORTS} replace/>}/>
                        <Route
                            path={ProtectedRouter.DASHBOARD}
                            element={
                                <Dashboard/>
                            }
                        >
                            <Route
                                path={ProtectedRouter.PROFILES}
                                element={
                                    <Profiles/>
                                }
                            />
                            <Route
                                path={ProtectedRouter.USERS}
                                element={
                                    <Users/>
                                }
                            />
                            <Route
                                path={ProtectedRouter.COMPANIES}
                                element={
                                    <Companies/>
                                }
                            />
                            <Route
                                path={ProtectedRouter.LOCATIONS}
                            >
                                <Route
                                    index
                                    element={
                                        <Locations/>
                                    }
                                />
                            </Route>
                        </Route>

                        <Route
                            path={ProtectedRouter.SUBMISSION}
                        >
                            <Route
                                index
                                element={
                                    <SubmissionList/>
                                }
                            />
                            <Route
                                path={ProtectedRouter.SUBMISSION_PROCESS}
                                element={
                                    <SubmissionProcess/>
                                }
                            />
                            <Route
                                path={ProtectedRouter.SUBMISSION_CREATE}
                                element={
                                    <SubmissionForm/>
                                }
                            />
                        </Route>

                        <Route
                            path={ProtectedRouter.TASKS}
                        >
                            <Route
                                index
                                element={
                                    <TaskList/>
                                }
                            />
                            <Route
                                path={ProtectedRouter.TASKS_CREATE}
                                element={
                                    <TaskCreateForm/>
                                }
                            />
                        </Route>

                        <Route
                            path={ProtectedRouter.TRANSPORTS}
                        >
                            <Route
                                index
                                element={
                                    <Transport/>
                                }
                            />
                            <Route
                                path={ProtectedRouter.TRANSPORT}
                            >
                                <Route
                                    index
                                    element={
                                        <TransportCreateForm/>
                                    }
                                />
                            </Route>
                        </Route>
                    </Route>
                    <Route path={PublicRouter.NOT_FOUND} element={<NotFound/>}/>
                    <Route path={PublicRouter.DENIED} element={<AccessDenied/>}/>
                    <Route path="*" element={<NotFound/>}/>
                </Routes>
            </AuthProvider>
        </Theme>
    )
        ;
};

export default App;
