// export const getTheme = (item: Item) => {
//   if (item === "Default") {
//     return presetGpnDefault;
//   }
//   if (item === "Dark") {
//     return presetGpnDark;
//   }
//   return presetGpnDisplay;
// };
import {useState} from "react";
import {presetGpnDark, presetGpnDefault, ThemePreset} from "@consta/uikit/Theme";
import {IconMoon} from "@consta/uikit/IconMoon";
import {IconSun} from "@consta/uikit/IconSun";
import {IconComponent} from "@consta/uikit/Icon";

export type ThemeType = {
    label: string;
    value: ThemePreset;
    icon: IconComponent;
};

export const items: ThemeType[] = [
    {
        value: presetGpnDefault,
        label: 'Default',
        icon: IconSun,
    },
    {
        value: presetGpnDark,
        label: 'Dark',
        icon: IconMoon,
    },
];

export const useThemeToggle = () => {

    const [theme, setTheme] = useState<ThemeType>(items[0]);
    const handleToggle = () => {
        setTheme(items.find(item => item.label !== theme.label)!);
    };


    return {
        themeItems: items,
        theme,
        setToggle: handleToggle,
    };

}