export const formatDateTime = (dateTime: string | undefined,
                               default_output: string | null | undefined = null,
                               options: any = undefined) => {
    if (!dateTime) {
        return default_output;
    }
    return new Date(Date.parse(dateTime)).toLocaleString('ru-RU', options);
}

export const formatDate = (dateTime: string | undefined,
                           default_output: string | null | undefined = null,
                           options: any = undefined) => {
    if (!dateTime) {
        return default_output;
    }
    return new Date(Date.parse(dateTime)).toLocaleDateString('ru-RU');
}

export function timerStringToInt(timer: string | any) {
    const regex = /P(\d+)DT(\d{2,})H(\d{2,})M(\d{2,})S/gm;
    const match = [...timer.matchAll(regex)];
    if (!match) {
        return undefined;
    }
    return parseInt(match[0][1].toString()) * 60 * 60 * 24 +
        parseInt(match[0][2].toString()) * 60 * 60 +
        parseInt(match[0][3].toString()) * 60 +
        parseInt(match[0][4].toString());
}

export function secondsToTimedeltaString(seconds: number, displaySeconds: boolean = true) {
    const days = Math.floor(seconds / (60 * 60 * 24));
    const hours = Math.floor((seconds % (60 * 60 * 24)) / (60 * 60));
    const minutes = Math.floor((seconds % (60 * 60)) / 60);
    let dayLabel = 'день';
    if ((days % 10 >= 5 && days % 10 <= 9) || (days >= 10 && days <= 20)) {
        dayLabel = 'дней'
    } else if (days % 10 >= 2 && days % 10 <= 4) {
        dayLabel = 'дня'
    }
    return `${days ? days + ' ' + dayLabel : ''} ${zeroPad(hours, 2)}:${zeroPad(minutes, 2)}${displaySeconds ? 
        ':' + zeroPad(seconds % 60, 2) : ''}`;
}

export function timerStringToDate(timer: string | any) {
    const temp = timerStringToInt(timer);
    if (!temp) {
        return undefined;
    }
    const date = new Date(temp * 1000);
    const userTimezoneOffset = date.getTimezoneOffset();
    return new Date(date.getTime() + userTimezoneOffset * 60000);
}

export function dateToTimedelta(date?: Date) {
    if (date) {
        return (date.getHours() * 60 * 60 + date.getMinutes() * 60 + date.getSeconds() * 60 + date.getMilliseconds()) * 1000;
    }
}

export function getTimedeltaNumber(datetime: any) {
    return datetime.getHours() * 60 * 60 + datetime.getMinutes() * 60 + datetime.getSeconds();
}

export const zeroPad = (num: any, places: number) => String(num).padStart(places, '0')

export const displayTimedelta = (timedelta: any) => {
    if (!timedelta) {
        return undefined;
    }
    const regex = /P(\d+)DT(\d+)H(\d+)M(\d+)S/gm;
    const match = [...timedelta.matchAll(regex)];
    if (!match) {
        return undefined;
    }

    return `${match[0][1] !== '0' ? parseInt(match[0][1].toString()) +
        ' дней' : ''}${match[0][2] ? ' ' + zeroPad(parseInt(match[0][2].toString()), 2) + ':' : ''}${match[0][3] ? zeroPad(parseInt(match[0][3].toString()), 2) : ''}`;
}

export const displayTaskStatus = (status: string | undefined) => {
    if (!status) {
        // return undefined;
        return 'СОЗДАН'
    }
    const statuses: { [index: string]: string } = {
        ASSIGNED: 'Назначен водитель',
        UNASSIGNED: 'ТС не на линии',
        ACCEPTED: 'Принят',
        STARTED: 'Начал выполнение',
        FINISH: 'Завершено',
        ARRIVED: 'Прибыл на объект',
        DECLINED: 'Отклонена водителем',
        CANCELED: 'Отменен',
        ARCHIVED: 'В архиве',
        RESMENA: 'Пересменка',
    };
    return statuses[status] ?? status;
}

export const displayTaskStatusStyle = (status?: string, default_red: string = 'error') => {
    if (!status) {
        return 'warning';
    }
    const statuses: any = {
        ASSIGNED: 'normal',
        UNASSIGNED: 'warning',
        ACCEPTED: 'normal',
        STARTED: 'normal',
        FINISH: 'success',
        ARRIVED: 'normal',
        DECLINED: default_red,
        CANCELED: default_red,
        ARCHIVED: 'system',
    };
    return statuses[status];
}

export const displaySubmissionTransportTypeStatus = (status: string | undefined) => {
    if (!status) {
        return undefined;
    }
    const statuses: { [index: string]: string } = {
        CREATED: 'Создан',
        // add
        ASSIGNED: 'Назначен',
        ACCEPTED: 'Подтвержден',
        AWAITING: 'В ожидании',
        DECLINED: 'Отклонен',
    };
    return statuses[status];
}

export const displaySmenaType = (type: string | undefined) => {
    if (!type) {
        return undefined;
    }
    const types: { [index: string]: string } = {
        DAY: 'Дневная',
        NIGHT: 'Ночная',
    };
    return types[type];
}

export const displaySubmissionTransportTypeStatusStyle = (status?: string) => {
    if (!status) {
        return 'warning';
    }
    const statuses: { [index: string]: "warning" | "normal" | "success" | "error" | "system" | undefined } = {
        CREATED: 'system',
        ACCEPTED: 'success',
        AWAITING: 'warning',
        DECLINED: 'error',
    };
    return statuses[status];
}

