import * as React from 'react';
import {ChangeEvent, FunctionComponent, useCallback, useEffect, useRef, useState} from 'react';
import {
    SubmissionsService,
    SubmissionWithTransportTypeCount,
} from "../../../api";
import {Table, TableColumn, TableTextFilter} from "@consta/uikit/Table";
import {ListPage} from "../../../components/ListPage";
import styles from "./SubmissionList.module.css";
import {Layout} from "@consta/uikit/Layout";
import {formatDate, formatDateTime} from "../../../utils/helpers";
import {Badge} from "@consta/uikit/Badge";
import {ProtectedRouter} from "../../../Routers";
import {Text} from "@consta/uikit/Text";
import {Status} from "../../../components/Status";
import {IconHome} from "@consta/uikit/IconHome";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {Toast} from "../../../components/Toast";
import {Button} from "@consta/uikit/Button";
import {IconAdd} from "@consta/uikit/IconAdd";
import {Loader} from "@consta/uikit/Loader";
import {FilterPageEnum} from "../../../utils/enum";
import {OrderType} from "@consta/uikit/__internal__/src/components/Table/helpers";
import {TextField} from "@consta/uikit/TextField";
import {IconFunnel} from "@consta/uikit/IconFunnel";
import {Switch} from "@consta/uikit/Switch";
import {useDebounce} from "@consta/uikit/useDebounce";
import {DragNDropField} from '@consta/uikit/DragNDropField';

import {DatePicker} from '@consta/uikit/DatePicker';
import {Popup} from "../../../components/Popup";
import {ContextMenu} from '@consta/uikit/ContextMenu';
import {useMediaQuery} from 'react-responsive';
import {useAuth} from '../../../contexts/AuthContext';

const SubmissionList: FunctionComponent = () => {

        const useauth = useAuth().user.email
        const isDesktop = useMediaQuery({minWidth: 1024}); // ширина экрана для десктопной версии
        const [submissions, setSubmissions] = useState<SubmissionWithTransportTypeCount[]>([]);
        const [alert, setAlert] = useState<any>({text: '', status: 'success'});
        const location = useLocation();
        const navigate = useNavigate();
        const [searchParams, setSearchParams] = useSearchParams();
        const [loading, setLoading] = useState<boolean>(false);
        const [page, setPage] = useState<number>(searchParams.get("page") ? parseInt(searchParams.get("page")!) : 1);
        const [totalPages, setTotalPages] = useState<number>(1);

        //DATE FILTER
        const [filterByDate, setFilterByDate] = useState<string | null>(searchParams.get(FilterPageEnum.Search) ?? null);
        const [searchByDate, setSearchByDate] = useState<string | null>(filterByDate);
        const debounceSetSearchValue = useDebounce(setSearchByDate, 500);
        const [dateValue, setDateValue] = useState<Date | null>(null);
        const [loadDateValue, setLoadDateValue] = useState(new Date());
        //--

        // status filter
        const [statusCompleteFilter, setStatusCompleteFilter] = useState<boolean | undefined>(searchParams.get(FilterPageEnum.StatusComplete) === 'false' ? false : true);
        // // --

        // modal
        const [isImportModalOpen, setIsImportModalOpen] = useState<boolean>(false);
        // const [isReportModalOpen, setIsReportModalOpen] = useState<boolean>(false);
        // --

        // report button
        const [isOpen, setIsOpen] = useState<boolean>(false);
        const ref = useRef(null);
        type Item = {
            label: string;
            event?: () => void;
        };
        const items: Item[] = [
            {
                label: 'Отчет по сменам',
                event: () => reportHandler('smena_report')
            },
            {
                label: 'Отчет по задачам',
                event: () => reportHandler('task_report')
            }
        ];
        const getItemOnClick = (item: Item) => {
            if (item.event) {
                return item.event;
            }
            return () => alert(item.label);
        };
        // --

        // megasearch
        const [searchFieldValue, setSearchFieldValue] = useState<string | null>(searchParams.get(FilterPageEnum.MegaSearch) ?? null);
        const [searchBy, setSearchBy] = useState<string | null>(searchFieldValue);
        const debounceSetSearchFieldValue = useDebounce(setSearchBy, 500);
        //


        const breadCrumbs = [
            {
                label: 'Главная',
                href: ProtectedRouter.SUBMISSION,
                icon: IconHome
            },
            {
                label: 'Заявки',
            },
        ];

        // IMPORT XLSX
        const importHandler = useCallback(
            (file: File) => {
                SubmissionsService.upload({file: file})
                    .then((result) => {
                        setAlert({
                            status: 'success',
                            text: `Импорт успешно завершен! Загружено заявок: ${result.total_import}`,
                        });
                        console.log('File uploaded successfully', result);
                        setIsImportModalOpen(false);
                        setTimeout(() => {
                            setDateValue(new Date(result.datepicker_date));
                        }, 0);
                    })
                    .catch((error) => {
                        setAlert({
                            status: 'alert',
                            text: 'Ошибка импорта! Проверьте загружаемый файл!',
                        });
                        console.error('Failed to upload file', error);
                    });
            }, [dateValue]);

        // REPORT
        const reportHandler = useCallback((reportType: string) => {
            let reportFunction;
            console.log(reportType)
            if (reportType === 'task_report') {
                reportFunction = SubmissionsService.taskReport;
            } else if (reportType === 'smena_report') {
                reportFunction = SubmissionsService.smenaReport;
            } else {
                console.error('Invalid report type');
                return;
            }

            reportFunction()
                .then(response => {
                    const binaryData = atob(response);
                    const arrayBuffer = new ArrayBuffer(binaryData.length);
                    const uint8Array = new Uint8Array(arrayBuffer);
                    for (let i = 0; i < binaryData.length; i++) {
                        uint8Array[i] = binaryData.charCodeAt(i);
                    }
                    const blob = new Blob([uint8Array], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${reportType}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    setAlert({
                        status: 'success',
                        text: `Отчет сформирован. Загрузка скоро начнется`,
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
        }, []);
        // --

        useEffect(() => {
            debounceSetSearchFieldValue(searchFieldValue);
        }, [searchFieldValue]);

        useEffect(() => {
            if (searchBy) {
                searchParams.set(FilterPageEnum.MegaSearch, searchBy)
            } else {
                searchParams.delete(FilterPageEnum.MegaSearch)
            }
            setSearchParams(searchParams);
        }, [searchBy]);


        // FILTER
        useEffect(() => {
            debounceSetSearchValue(filterByDate);
        }, [filterByDate]);

        useEffect(() => {
            setFilterByDate(dateValue?.toISOString() ?? null);
        }, [dateValue]);

        useEffect(() => {
            if (searchByDate) {
                searchParams.set(FilterPageEnum.Search, searchByDate)
            } else {
                searchParams.delete(FilterPageEnum.Search)
            }
            setSearchParams(searchParams);
        }, [searchByDate]);

        useEffect(() => {
            document.title = searchParams.get("page") ? `Список заявок. Стр. ${page} | SMA` : 'Список заявок | SMA';
        }, [page]);

        useEffect(() => {
            searchParams.forEach((item, key) => {
                if (item === 'undefined') {
                    searchParams.delete(key);
                }
            })
            setSearchParams(searchParams);
        }, [searchParams]);

        // STATUS FILTER
        useEffect(() => {
            if (statusCompleteFilter) {
                searchParams.set(FilterPageEnum.StatusComplete, String(statusCompleteFilter));
            } else {
                searchParams.delete(FilterPageEnum.StatusComplete);
            }
            setSearchParams(searchParams);
        }, [statusCompleteFilter]);
        //--

        useEffect(() => {
            setLoading(true);
            SubmissionsService.getAll(
                searchParams.getAll("status"),
                searchByDate ?? undefined,
                statusCompleteFilter ?? undefined,
                searchFieldValue ?? undefined,
                page)
                .then((data: any) => {
                    setSubmissions([...data.items]);
                    setTotalPages(Math.ceil(data.count / 200));
                })
                .finally(() => setLoading(false));
        }, [page, searchParams]);

        useEffect(() => {
            // @ts-ignore
            const alertLocation = location?.state?.alert;
            if (alertLocation) {
                if (alertLocation.action === 'removed') {
                    setAlert({text: `Заявка ${alertLocation.name} успешно удалена!`, status: 'success'})
                }
                if (alertLocation.action === 'created') {
                    setAlert({text: `Заявка ${alertLocation.name} успешно создана!`, status: 'success'})
                }
                navigate(location.pathname, {replace: true});
            }
        }, [location]);


        const columns: TableColumn<any>[] = [
            {
                title: 'UUID',
                accessor: 'id',
                hidden: true
            },
            {
                title: 'Номер заявки',
                accessor: 'name',
                sortable: true,
                width: 130,
                renderCell: ({id, name}) => (
                    <Text as='a'
                          href={ProtectedRouter.SUBMISSION_PROCESS.replace(':submissionId', id)}
                          size='s'
                          view='link'
                    >{name}</Text>
                )
            },
            {
                title: 'Дата',
                accessor: 'date',
                sortable: true,
                sortByField: 'name',
                renderCell: ({date}) => formatDate(date)
            },
            {
                title: 'Куст',
                accessor: 'bush',
                sortable: true,
                renderCell: ({wells}) => <b>{wells?.bush?.name}</b>
            },
            {
                title: 'Скважина',
                accessor: 'wells',
                sortable: true,
                renderCell: ({wells}) => <b>{wells?.name}</b>
            },
            {
                title: 'Qн',
                accessor: 'oildeb',
                sortable: true,
                renderCell: ({oildeb}) => <b>{oildeb}</b>
            },
            {
                title: 'Бригада',
                accessor: 'brigade',
                sortable: true,
                renderCell: ({brigade}) => brigade.name
            },
            {
                title: 'Статус',
                accessor: 'status',
                sortable: true,
                renderCell: ({status}) => (<Status status={status}/>)
            },
            {
                title: 'Транспорт',
                accessor: 'transports',
                sortable: true,
                renderCell: ({transports}) => {
                    const temp = transports.reduce((prev: any, curr: any) => {
                        prev[curr.type.id] = {
                            ...curr,
                            count: prev[curr.type.id]?.count ? prev[curr.type.id]?.count + 1 : 1
                        };

                        return prev;
                    }, {});
                    return (
                        <Layout direction='column'>{
                            Object.values(temp).map((item: any) => {
                                return <Layout className={styles.Table__TransportCell}
                                               key={item.worktype?.type?.id}>
                                    <Text size='s' weight="bold">{item.worktype?.type?.name}</Text>
                                    {/*<Text size='s'>({item.worktype.name})</Text>*/}
                                    <Badge status='normal' label={`x${item.count}`} view='stroked' form='round'
                                           size='s'/>
                                </Layout>

                            })
                        }</Layout>
                    );
                }
            },
            {
                title: 'Создана',
                accessor: 'created_at',
                sortable: true,
                renderCell: ({created_at}) => formatDateTime(created_at)
            },
        ];
        const filters = [
            {
                id: 'status',
                name: 'Статус: ',
                field: 'status',
                filterer: (
                    cellValue: string,
                    filterValues: Array<{ value: string; name: string }>,
                ) => {
                    return filterValues.some((filterValue) => filterValue && filterValue.value === cellValue,);
                },
                component: {
                    name: TableTextFilter,
                    props: {
                        items: [
                            {name: 'Создана', value: 'CREATED'},
                            {name: 'Обработан', value: 'PROCESSED'},
                            {name: 'Обработан частично', value: 'PROCESSEDH'},
                            {name: 'В ожидании', value: 'AWAITING'},
                            {name: 'Отклонена', value: 'DECLINED'},
                        ],
                    },
                },
            },

        ];
        return (
            <ListPage breadCrumbs={breadCrumbs} page={page} totalPages={totalPages} setPage={setPage}>

                <Layout className={styles.Actions}>

                    <Layout className={styles.ActionsFilters} direction='row'>

                        <DatePicker
                            value={dateValue || loadDateValue}
                            type="date"
                            format="dd-MM-yyyy"
                            onChange={({value}) => {
                                setDateValue(value);
                                setFilterByDate(value?.toISOString() ?? null);
                            }}
                        />
                        <TextField placeholder="Поиск..."
                                   onChange={({value}) => setSearchFieldValue(value)}
                                   value={searchFieldValue}
                                   withClearButton
                        />
                        <Switch checked={statusCompleteFilter}
                                onChange={() => setStatusCompleteFilter(!statusCompleteFilter)}
                                label='Скрыть обработанные'
                                size="s"
                        />
                    </Layout>

                    {isDesktop && (
                    <Layout className={styles.ActionsFilters} direction='row'>
                        {useauth !== 'utt1@cud-hnt.ru' && (
                            <>
                            <Button iconLeft={IconAdd}
                                label='Добавить'
                                onClick={() => navigate(ProtectedRouter.CREATE)}
                        />
                            <Button
                                iconLeft={IconAdd}
                                label='Импорт заявок'
                                onClick={() => setIsImportModalOpen(true)}
                            />
                            </>
                        )}

                        <Button ref={ref} label="Отчеты" onClick={() => setIsOpen(!isOpen)}/>
                        <ContextMenu
                            isOpen={isOpen}
                            items={items}
                            anchorRef={ref}
                            getItemOnClick={getItemOnClick}
                            onClickOutside={() => setIsOpen(false)}
                            direction="downStartLeft"
                        />
                    </Layout>
                    )}
                </Layout>

                {!isDesktop && (
                    <Layout className={styles.ActionsFilters} direction='row'>
                        <Button iconLeft={IconAdd}
                                label='Добавить'
                                onClick={() => navigate(ProtectedRouter.CREATE)}
                        />
                        <Button ref={ref} label="Отчеты" onClick={() => setIsOpen(!isOpen)}/>
                        <ContextMenu
                            isOpen={isOpen}
                            items={items}
                            anchorRef={ref}
                            getItemOnClick={getItemOnClick}
                            onClickOutside={() => setIsOpen(false)}
                            direction="downStartLeft"
                        />
                    </Layout>
                    )}




                <Table className={styles.Table}
                       columns={columns}
                       rows={submissions}
                       stickyHeader={true}
                       borderBetweenRows
                       filters={filters}
                       minColumnWidth={100}
                       onFiltersUpdated={({status}) => {
                           const statusList = status?.value?.map((item: any) => item.value);
                           searchParams.delete(FilterPageEnum.Search)
                           setSearchParams({
                               ...searchParams,
                               status: statusList
                           });
                       }}
                       emptyRowsPlaceholder={(loading ? <Loader className={styles.Loader}/> : undefined)}
                />

                <Popup className={styles.Modal__Container}
                       isOpen={isImportModalOpen}
                       onClose={() => setIsImportModalOpen(false)}
                >
                    <Layout className={styles.Modal__Content}
                            direction='column'
                    >
                        <Layout direction='column' className={styles.Modal__Fieldset}>
                            <DragNDropField multiple={false} accept={['.xlsx']} onDropFiles={(files) => {
                                importHandler(files[0]);
                            }}>
                                {({openFileDialog}) => (
                                    <>
                                        <Text style={{textAlign: 'center'}}>Перетащите файл импорта заявок сюда или
                                            загрузите по кнопке. За раз можно загружать только один файл. Допустимое
                                            разрешение для загрузки файла .xlsx</Text>
                                        <Text view="ghost" font="mono">.xlsx</Text>
                                        <br/>
                                        <Button onClick={openFileDialog} label="Выбрать файл"/>
                                    </>
                                )}
                            </DragNDropField>
                        </Layout>
                    </Layout>
                </Popup>

                {/*<Popup className={styles.Modal__Container}*/}
                {/*       isOpen={isReportModalOpen}*/}
                {/*       onClose={() => setIsReportModalOpen(false)}*/}
                {/*>*/}
                {/*    <Layout className={styles.Modal__Content}*/}
                {/*            direction='column'*/}
                {/*    >*/}
                {/*        <Layout direction='column' className={styles.Modal__Fieldset}>*/}
                {/*            <Button*/}
                {/*                iconLeft={IconAdd}*/}
                {/*                label='Отчет по заданиям'*/}
                {/*                onClick={() => reportHandler()}*/}
                {/*            />*/}
                {/*        </Layout>*/}
                {/*    </Layout>*/}
                {/*</Popup>*/}

                <Toast alert={alert} setAlert={setAlert}/>
            </ListPage>

        );
    }
;
export {SubmissionList};
