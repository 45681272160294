/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagedTask } from '../models/PagedTask';
import type { Task } from '../models/Task';
import type { TaskCreate } from '../models/TaskCreate';
import type { TaskWithHistory } from '../models/TaskWithHistory';
import type { TaskWithSmena } from '../models/TaskWithSmena';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TasksService {

    /**
     * Create Task
     * @param requestBody 
     * @returns TaskWithSmena OK
     * @throws ApiError
     */
    public static createTask(
requestBody: TaskCreate,
): CancelablePromise<TaskWithSmena> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/tasks',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * List Tasks
     * @param status 
     * @param startDate 
     * @param endDate 
     * @param statusComplete 
     * @param page 
     * @returns PagedTask OK
     * @throws ApiError
     */
    public static listTasks(
status?: Array<string>,
startDate?: string,
endDate?: string,
statusComplete?: boolean,
page: number = 1,
): CancelablePromise<PagedTask> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/tasks',
            query: {
                'status': status,
                'start_date': startDate,
                'end_date': endDate,
                'status_complete': statusComplete,
                'page': page,
            },
        });
    }

    /**
     * Get Task
     * @param taskId 
     * @returns Task OK
     * @throws ApiError
     */
    public static getTask(
taskId: string,
): CancelablePromise<Task> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/tasks/{task_id}',
            path: {
                'task_id': taskId,
            },
        });
    }

    /**
     * Update Task
     * @param taskId 
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static updateTask(
taskId: string,
requestBody: TaskCreate,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/tasks/{task_id}',
            path: {
                'task_id': taskId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete Task
     * @param taskId 
     * @returns any OK
     * @throws ApiError
     */
    public static deleteTask(
taskId: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/tasks/{task_id}',
            path: {
                'task_id': taskId,
            },
        });
    }

    /**
     * Get Task With History
     * @param taskId 
     * @returns TaskWithHistory OK
     * @throws ApiError
     */
    public static getTaskWithHistory(
taskId: string,
): CancelablePromise<TaskWithHistory> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/tasks/{task_id}/history',
            path: {
                'task_id': taskId,
            },
        });
    }

    /**
     * Cancel Task
     * @param taskId 
     * @param comment 
     * @returns any OK
     * @throws ApiError
     */
    public static cancelTask(
taskId: string,
comment: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/tasks/cancel/{task_id}',
            path: {
                'task_id': taskId,
            },
            query: {
                'comment': comment,
            },
        });
    }

    /**
     * Complete Task
     * @param taskId 
     * @param comment 
     * @returns any OK
     * @throws ApiError
     */
    public static completeTask(
taskId: string,
comment: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/tasks/finish/{task_id}',
            path: {
                'task_id': taskId,
            },
            query: {
                'comment': comment,
            },
        });
    }

}
