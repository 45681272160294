import * as React from 'react';
import {FormEvent, useCallback, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {Form} from "../../components/Form";
import {Select} from "@consta/uikit/Select";
import {TransportCreate, TransportsService, TransportType} from "../../api";

import styles from './TransportCreate.module.css';
import {TextField} from '@consta/uikit/TextField';

type Field = {
    title: string;
}


type TransportCreateProps = {}

const TransportCreateForm: React.FC<TransportCreateProps> = () => {

    const navigate = useNavigate();
    const [transportTypes, setTransportTypes] = useState<TransportType[]>();
    const [regNumberCaption, setRegNumberCaption] = useState();
    const [errors, setErrors] = useState();
    const [payload, setPayload] = useState<TransportCreate>({type_id: '', reg_number: ''});

    useEffect(() => {
        TransportsService.listTransportTypes().then((data: TransportType[]) => {
            setTransportTypes([...data])
        })
    }, [])

    const handleSubmit = useCallback((e: FormEvent) => {
        e.preventDefault();


    }, []);

    return (
        <Form
            mode='create'
            label='Единица транспорта'
            onSubmit={handleSubmit}
            errors={errors}
        >
            {
                transportTypes &&
                <Select
                    className={styles.Field}
                    placeholder="Выберите тип техники"
                    label='Тип техники'
                    getItemKey={(item: any) => item.id}
                    getItemLabel={(item: any) => item.name}
                    value={payload ? transportTypes?.find((type) => type.id === payload!.type_id) : null}
                    onChange={({value}) => setPayload({...payload, type_id: value!.id})}
                    items={transportTypes}
                />
            }
            <TextField
                className={styles.Field}
                onChange={({value}) => setPayload({...payload, reg_number: value!})}
                value={payload.reg_number}
                type="text"
                label="Гос.номер"
                placeholder="а000аа00"
                status={!payload.reg_number || payload.reg_number.match(/[авеклморстух]\d{3}[авеклморстух]{2}\d{2,3}/i) ? undefined : 'alert'}
                caption={!payload.reg_number || payload.reg_number.match(/[авеклморстух]\d{3}[авеклморстух]{2}\d{2,3}/i) ? undefined : 'неверный формат'}
                autoFocus
            />

        </Form>
    );
};

export {TransportCreateForm};
