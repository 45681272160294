import * as React from 'react';
import {FunctionComponent, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {ProtectedRouter} from '../../Routers';
import {useAuth} from '../../contexts/AuthContext';
import {useForm} from "react-hook-form";
import {Layout} from "@consta/uikit/Layout";
import styles from './Login.module.css';
import {TextField} from "@consta/uikit/TextField";
import {TokenObtain} from "../../api";
import {Button} from "@consta/uikit/Button";
import {Text} from "@consta/uikit/Text";


const Login: FunctionComponent = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [apiError, setApiError] = useState<string | undefined>();
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: {errors, isSubmitting, isSubmitSuccessful},
        reset
    } = useForm<TokenObtain>();
    const onSubmit = (data: any) => login(data)
        .then(reset)
        .catch((err: any) => setApiError(err.detail ?? err.toString()));

    // @ts-ignore
    const from = location.state?.from?.pathname || ProtectedRouter.USERS;
    const {user, login} = useAuth();
    if (user) {
        navigate(from, {replace: true});
    }

    useEffect(() => {
        document.title = 'Авторизация | SMA';
    }, []);


    return (
        <>
            <Layout as='form' onSubmit={handleSubmit(onSubmit)} className={styles.Login}>
            <Layout onSubmit={handleSubmit(onSubmit)} className={styles.Login__Container}>
                <TextField
                        label='Email' value={watch('email')}
                        id='email'
                        {...register('email', {required: 'Поле обязательно к заполнению', min: 3})}
                        required={true}
                        onChange={({value}) => setValue('email', value!)}
                        status={errors.email && 'alert'}
                        caption={errors.email && errors.email.message}
                />
                <TextField
                        label='Пароль'
                        value={watch('password')}
                        id='password'
                        type='password'
                        {...register('password', {required: 'Поле обязательно к заполнению', min: 3})}
                        required={true}
                        onChange={({value}) => setValue('password', value!)}
                        status={errors.password && 'alert'}
                        caption={errors.password && errors.password.message}
                />
                <Button view='primary' type='submit' label='Войти' width='full'
                        className={styles.Login__Submit}
                        loading={isSubmitting}
                />
                <Text view='alert'>{apiError}</Text>
            </Layout>
            </Layout>
        </>
    );
};

export {Login};
