import * as React from "react";
import {FunctionComponent} from "react";
import {Button} from "@consta/uikit/Button";
import {Popup} from "../Popup";

type Props = {
    title?: string,
    isOpen: boolean,
    onConfirm: () => void,
    onClose: () => void,
}

export const Confirm: FunctionComponent<Props> = ({title, isOpen, onConfirm, onClose}) => {

    return (
        <Popup onClose={onClose} isOpen={isOpen} title={title}>
            <Button label='Да'
                    type="button"
                    style={{width: 150}}
                    onClick={onConfirm}
            />
        </Popup>
    );
}