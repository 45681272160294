/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WorkTypeSchema } from '../models/WorkTypeSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WorktypeService {

    /**
     * List Worktype
     * @returns WorkTypeSchema OK
     * @throws ApiError
     */
    public static listWorktype(): CancelablePromise<Array<WorkTypeSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/worktype',
        });
    }

}
