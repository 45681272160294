/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BushSchema } from '../models/BushSchema';
import type { WellsSchema } from '../models/WellsSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WellsService {

    /**
     * List Wells
     * @returns WellsSchema OK
     * @throws ApiError
     */
    public static listWells(): CancelablePromise<Array<WellsSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/wells',
        });
    }

    /**
     * List Bush
     * @returns BushSchema OK
     * @throws ApiError
     */
    public static listBush(): CancelablePromise<Array<BushSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/bush',
        });
    }

}
