import * as React from 'react';
import {FunctionComponent, useEffect, useState} from 'react';
import {ApiError, ProfilesService, CompanyDetail, PagedCompanyDetail, PagedPersonWithAll} from "../../api";
import {Table, TableColumn} from "@consta/uikit/Table";
import {formatDateTime} from "../../utils/helpers";
import {ListPage} from "../../components/ListPage";
import {ProtectedRouter, PublicRouter} from "../../Routers";
import {IconHome} from "@consta/uikit/IconHome";
import {Layout} from "@consta/uikit/Layout";
import styles from "./Profiles.module.css";
import {Button} from "@consta/uikit/Button";
import {IconAdd} from "@consta/uikit/IconAdd";
import {useNavigate, useSearchParams} from "react-router-dom";
import {IconEdit} from "@consta/uikit/IconEdit";
import {IconTrash} from "@consta/uikit/IconTrash";
import {Confirm} from "../../components/Confirm";
import {Toast} from "../../components/Toast";
import {Form} from "../../components/Form";
import {Popup} from "../../components/Popup";
import {FieldRow} from "../../components/FieldRow";
import {TextField} from "@consta/uikit/TextField";
import {Text} from "@consta/uikit/Text";
import {useDebounce} from "@consta/uikit/useDebounce";
import {FilterPageEnum} from "../../utils/enum";
import {Checkbox} from "@consta/uikit/Checkbox";
import {Loader} from "@consta/uikit/Loader";

type Profile = {
    id?: string;
    name?: string;
    created_at?: string;
    updated_at?: string;
}

const Profiles: FunctionComponent = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [alert, setAlert] = useState<any>({text: '', status: 'success'});
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [errors, setErrors] = useState<string | undefined>(undefined);
    const [personToConfirmRemove, setPersonToConfirmRemove] = useState<string | undefined>(undefined);
    const [profile, setProfile] = useState<Profile>({});
    const [createModalIsOpen, setCreateModalIsOpen] = useState<boolean>(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState<boolean>(false);
    const [profiles, setProfiles] = useState<Profile[]>([]);
    const [page, setPage] = useState<number>(searchParams.get("page") ? parseInt(searchParams.get("page")!) : 1);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchFieldValue, setSearchFieldValue] = useState<string | null>(searchParams.get("q") ?? null);
    const [searchByName, setSearchByName] = useState<string | null>(searchParams.get("q") ?? null);
    const [sortBy, setSortBy] = useState<string | undefined>(searchParams.get("order") ?? undefined);
    const [totalPages, setTotalPages] = useState<number>(1);
    const debounceSetSearchValue = useDebounce(setSearchByName, 300);
    const name = 'Пользователь';
    const namePlural = 'Пользователи';


    useEffect(() => {
        debounceSetSearchValue(searchFieldValue)
    }, [searchFieldValue]);

    useEffect(() => {
        if (searchByName) {
            searchParams.set(FilterPageEnum.Search, searchByName)
        } else {
            searchParams.delete(FilterPageEnum.Search)
        }
        setSearchParams(searchParams);
    }, [searchByName]);

    useEffect(() => {
        document.title = `${namePlural} | Администрирование | SMA`;
    }, []);

    const breadCrumbs = [
        {
            label: 'Главная',
            href: ProtectedRouter.SUBMISSION,
            icon: IconHome
        },
        {
            label: namePlural,
        },
    ];

    useEffect(() => {
        if (!submitted) {
            setLoading(true);
            ProfilesService.getProfileListDetail(sortBy, searchByName ?? undefined, page)
                .then((data: PagedPersonWithAll) => {
                    setProfiles([...data.items!]);
                    setTotalPages(Math.max(page, Math.ceil(data.count / 10)));
                })
                .catch(errorHandler)
                .finally(() => setLoading(false));
        }
        setSubmitted(false);
    }, [submitted, page, sortBy, searchByName]);

    const errorHandler = (err: ApiError) => {
        console.log(JSON.stringify(err));
        let text = JSON.stringify(err);
        if (err.status === 404) {
            navigate(PublicRouter.NOT_FOUND);
        }
        if (typeof err?.body?.detail === "string") {
            text = err.body.detail;
        }
        if (Array.isArray(err?.body?.detail)) {
            text = err.body.detail.map((item: any) => item.msg).join('\n');
        }
        setAlert({status: 'alert', text})
    };

    function handleRemoveConfirm() {
        if (personToConfirmRemove) {
            ProfilesService.deleteProfile(personToConfirmRemove)
                .then((data) => {
                    if (data?.success) {
                        setAlert({text: `${name} удалена.`, status: 'success'});
                    }
                })
                .then(() => {
                    setPersonToConfirmRemove(undefined);
                    setSubmitted(true);
                })
                .catch(errorHandler);
        }
    }

    // function handleCreate() {
    //     const payload = {
    //         name: profile.name!,
    //     }
    //     ProfilesService.createProfile(payload)
    //         .then((data) => setAlert({text: `${name} "${data.name}" успешно создана.`, status: 'success'}))
    //         .then(() => {
    //             setSubmitted(true);
    //             closePopup();
    //         })
    //         .catch(errorHandler);
    // }
    //
    // function handleUpdate() {
    //     const payload = {
    //         name: profile.name!,
    //     }
    //     ProfilesService.updateProfile(profile.id!, payload)
    //         .then((data) => setAlert({text: `${name} "${data.name}" успешно обновлена.`, status: 'success'}))
    //         .then(() => {
    //             setSubmitted(true);
    //             closePopup();
    //         })
    //         .catch(errorHandler);
    // }

    function closePopup() {
        setCreateModalIsOpen(false);
        setEditModalIsOpen(false);
        setProfile({});
    }

    function handleSort(value: any) {
        if (value) {
            const order = (value.sortOrder === 'desc' ? '-' : '') + value.sortingBy;
            searchParams.set(FilterPageEnum.SortBy, order);
            setSortBy(order);
        } else {
            searchParams.delete(FilterPageEnum.SortBy);
            setSortBy(undefined);
        }
        setSearchParams(searchParams);
    }

    const columns: TableColumn<any>[] = [
        {
            title: 'UUID',
            accessor: 'id',
            hidden: true
        },
        {
            title: 'ФИО',
            accessor: 'fio',
            sortable: true,
        },
        {
            title: 'Телефон',
            accessor: 'phone',
        },
        {
            title: 'email',
            accessor: 'email',
            renderCell: ({user}) => user?.email
        },
        {
            title: 'telegram',
            accessor: '',
            renderCell: ({telegram_user}) => telegram_user ? `@${telegram_user?.username}` : ''
        },
        {
            title: 'Компания',
            accessor: 'company__name',
            sortable: true,
            renderCell: ({company}) => company?.name
        },
        {
            title: 'Админ?',
            accessor: 'user__is_staff',
            sortable: true,
            // renderCell: ({is_staff}) => (<Checkbox view="primary" checked={is_staff}/>)
        },
        {
            title: 'Создан',
            accessor: 'created_at',
            sortable: true,
            renderCell: ({created_at}) => formatDateTime(created_at)
        },
        // {
        //     title: 'Действия',
        //     accessor: 'actions',
        //     renderCell: (row) => (
        //         <Layout>
        //             <Button iconLeft={IconEdit}
        //                     onlyIcon
        //                     view='clear'
        //                     size='s'
        //                     onClick={() => {
        //                         setEditModalIsOpen(true);
        //                         setProfile({...row});
        //                     }}
        //             />
        //             <Button iconLeft={IconTrash}
        //                     onlyIcon
        //                     view='clear'
        //                     size='s'
        //                     onClick={() => setPersonToConfirmRemove(row.id)}
        //             />
        //         </Layout>
        //     )
        // },
    ];

    return (
        <>
            <ListPage breadCrumbs={breadCrumbs} page={page} totalPages={totalPages} setPage={setPage}>
                <Layout className={styles.Actions} direction='row'>
                    <Layout className={styles.Actions__Filters} direction='row'>
                        <TextField placeholder="Найти..."
                                   onChange={({value}) => setSearchFieldValue(value)}
                                   value={searchFieldValue}
                                   withClearButton
                        />
                    </Layout>
                    {/*<Layout className={styles.Actions__Buttons} direction='row'>*/}
                    {/*    <Button className={styles.ButtonAdd}*/}
                    {/*            view='secondary'*/}
                    {/*            label='Создать компанию'*/}
                    {/*            iconRight={IconAdd}*/}
                    {/*            onClick={() => setCreateModalIsOpen(true)}*/}
                    {/*    />*/}
                    {/*</Layout>*/}
                </Layout>
                <Confirm isOpen={!!personToConfirmRemove}
                         onConfirm={() => handleRemoveConfirm()}
                         onClose={() => setPersonToConfirmRemove(undefined)}
                         title={`Вы действительно хотите удалить компанию ${profiles.find((item) => item.id === personToConfirmRemove)?.name}?`}
                />
                <Toast alert={alert} setAlert={setAlert}/>
                <Table rows={profiles}
                       columns={columns}
                       onSortBy={handleSort}
                       emptyRowsPlaceholder={(loading ? <Loader className={styles.Loader}/> : undefined)}
                />
                {/*<Popup isOpen={createModalIsOpen || editModalIsOpen} onClose={closePopup}>*/}
                {/*    <Form className={styles.Modal__Form}*/}
                {/*          mode={createModalIsOpen ? 'create' : editModalIsOpen ? 'edit' : 'view'}*/}
                {/*          label='компанию'*/}
                {/*          showActions={true}*/}
                {/*          onSubmit={createModalIsOpen ? handleCreate :*/}
                {/*              editModalIsOpen ? handleUpdate :*/}
                {/*                  () => console.log('Not supported.')}*/}
                {/*          onClose={closePopup}*/}
                {/*    >*/}
                {/*        <Layout direction='column'>*/}
                {/*            {*/}
                {/*                editModalIsOpen &&*/}
                {/*                <>*/}
                {/*                    <FieldRow label='UUID:'>*/}
                {/*                        <Text>{profile.id}</Text>*/}
                {/*                    </FieldRow>*/}
                {/*                </>*/}
                {/*            }*/}
                {/*            <FieldRow label='Название:'>*/}
                {/*                <TextField value={profile.name}*/}
                {/*                           required*/}
                {/*                           onChange={({value}) => setProfile({*/}
                {/*                               ...profile, name: value!*/}
                {/*                           })}/>*/}
                {/*            </FieldRow>*/}
                {/*            {*/}
                {/*                editModalIsOpen &&*/}
                {/*                <>*/}
                {/*                    <FieldRow label='Создан в:'>*/}
                {/*                        <Text>{formatDateTime(profile.created_at)}</Text>*/}
                {/*                    </FieldRow>*/}
                {/*                    <FieldRow label='Обновлен в:'>*/}
                {/*                        <Text>{formatDateTime(profile.updated_at)}</Text>*/}
                {/*                    </FieldRow>*/}
                {/*                </>*/}
                {/*            }*/}
                {/*        </Layout>*/}
                {/*    </Form>*/}
                {/*</Popup>*/}
            </ListPage>
        </>
    );
};

export {Profiles};
