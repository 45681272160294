import * as React from 'react';
import {FunctionComponent, useEffect, useState} from 'react';
import {ApiError, LocationDetail, LocationsService, PagedLocationDetail} from "../../api";
import {Table, TableColumn} from "@consta/uikit/Table";
import {formatDateTime} from "../../utils/helpers";
import {ListPage} from "../../components/ListPage";
import {ProtectedRouter, PublicRouter} from "../../Routers";
import {IconHome} from "@consta/uikit/IconHome";
import {Layout} from "@consta/uikit/Layout";
import styles from "./Locations.module.css";
import {Button} from "@consta/uikit/Button";
import {IconAdd} from "@consta/uikit/IconAdd";
import {useNavigate, useSearchParams} from "react-router-dom";
import {IconEdit} from "@consta/uikit/IconEdit";
import {IconTrash} from "@consta/uikit/IconTrash";
import {Confirm} from "../../components/Confirm";
import {Toast} from "../../components/Toast";
import {Form} from "../../components/Form";
import {Popup} from "../../components/Popup";
import {FieldRow} from "../../components/FieldRow";
import {TextField} from "@consta/uikit/TextField";
import {Text} from "@consta/uikit/Text";
import {useDebounce} from "@consta/uikit/useDebounce";
import {FilterPageEnum} from "../../utils/enum";
import {Loader} from "@consta/uikit/Loader";

type Location = {
    id?: string;
    name?: string;
    created_at?: string;
    updated_at?: string;
}

const Locations: FunctionComponent = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [alert, setAlert] = useState<any>({text: '', status: 'success'});
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [errors, setErrors] = useState<string | undefined>(undefined);
    const [locationToConfirmRemove, setLocationToConfirmRemove] = useState<string | undefined>(undefined);
    const [location, setLocation] = useState<Location>({});
    const [createModalIsOpen, setCreateModalIsOpen] = useState<boolean>(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState<boolean>(false);
    const [locations, setLocations] = useState<LocationDetail[]>([]);
    const [page, setPage] = useState<number>(searchParams.get("page") ? parseInt(searchParams.get("page")!) : 1);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchFieldValue, setSearchFieldValue] = useState<string | null>(searchParams.get("q") ?? null);
    const [searchByName, setSearchByName] = useState<string | null>(searchParams.get("q") ?? null);
    const [sortBy, setSortBy] = useState<string | undefined>(searchParams.get("order") ?? undefined);
    const [totalPages, setTotalPages] = useState<number>(1);
    const debounceSetSearchValue = useDebounce(setSearchByName, 300);

    useEffect(() => {
        debounceSetSearchValue(searchFieldValue)
    }, [searchFieldValue]);

    useEffect(() => {
        if (searchByName) {
            searchParams.set(FilterPageEnum.Search, searchByName)
        } else {
            searchParams.delete(FilterPageEnum.Search)
        }
        setSearchParams(searchParams);
    }, [searchByName]);

    useEffect(() => {
        document.title = `Локации | Администрирование | SMA`;
    }, []);

    const breadCrumbs = [
        {
            label: 'Главная',
            href: ProtectedRouter.SUBMISSION,
            icon: IconHome
        },
        {
            label: 'Локации',
        },
    ];

    useEffect(() => {
        if (!submitted) {
            setLoading(true);
            LocationsService.listLocations(sortBy, searchByName ?? undefined, page)
                .then((data: PagedLocationDetail) => {
                    setLocations([...data.items!]);
                    setTotalPages(Math.max(page, Math.ceil(data.count / 10)));
                })
                .catch(errorHandler)
                .finally(() => setLoading(false));
        }
        setSubmitted(false);
    }, [submitted, page, sortBy, searchByName]);

    const errorHandler = (err: ApiError) => {
        console.log(JSON.stringify(err));
        let text = JSON.stringify(err);
        if (err.status === 404) {
            navigate(PublicRouter.NOT_FOUND);
        }
        if (typeof err?.body?.detail === "string") {
            text = err.body.detail;
        }
        if (Array.isArray(err?.body?.detail)) {
            text = err.body.detail.map((item: any) => item.msg).join('\n');
        }
        setAlert({status: 'alert', text})
    };

    function handleRemoveConfirm() {
        if (locationToConfirmRemove) {
            LocationsService.deleteLocation(locationToConfirmRemove)
                .then((data) => {
                    if (data?.success) {
                        setAlert({text: 'Локация удалена.', status: 'success'});
                    }
                })
                .then(() => {
                    setLocationToConfirmRemove(undefined);
                    setSubmitted(true);
                })
                .catch(errorHandler);
        }
    }

    function handleCreate() {
        const payload = {
            name: location.name!,
        }
        LocationsService.createLocation(payload)
            .then((data) => setAlert({text: `Локация "${data.name}" успешно создана.`, status: 'success'}))
            .then(() => {
                setSubmitted(true);
                closePopup();
            })
            .catch(errorHandler);
    }

    function handleUpdate() {
        const payload = {
            name: location.name!,
        }
        LocationsService.updateLocation(location.id!, payload)
            .then((data) => setAlert({text: `Локация "${data.name}" успешно обновлена.`, status: 'success'}))
            .then(() => {
                setSubmitted(true);
                closePopup();
            })
            .catch(errorHandler);
    }

    function closePopup() {
        setCreateModalIsOpen(false);
        setEditModalIsOpen(false);
        setLocation({});
    }

    function handleSort(value: any) {
        if (value) {
            const order = (value.sortOrder === 'desc' ? '-' : '') + value.sortingBy;
            searchParams.set(FilterPageEnum.SortBy, order);
            setSortBy(order);
        } else {
            searchParams.delete(FilterPageEnum.SortBy);
            setSortBy(undefined);
        }
            setSearchParams(searchParams);
    }

    const columns: TableColumn<any>[] = [
        {
            title: 'UUID',
            accessor: 'id',
            hidden: true
        },
        {
            title: 'Название',
            accessor: 'name',
            sortable: true,
        },
        {
            title: 'Создан',
            accessor: 'created_at',
            sortable: true,
            renderCell: ({created_at}) => formatDateTime(created_at)
        },
        {
            title: 'Изменен',
            accessor: 'updated_at',
            sortable: true,
            renderCell: ({updated_at}) => formatDateTime(updated_at)
        },
        {
            title: 'Действия',
            accessor: 'actions',
            renderCell: (row) => (
                <Layout>
                    {/*<Button iconLeft={IconEdit}*/}
                    {/*        onlyIcon*/}
                    {/*        view='clear'*/}
                    {/*        size='s'*/}
                    {/*        onClick={() => {*/}
                    {/*            setEditModalIsOpen(true);*/}
                    {/*            setLocation({...row});*/}
                    {/*        }}*/}
                    {/*/>*/}
                    {/*<Button iconLeft={IconTrash}*/}
                    {/*        onlyIcon*/}
                    {/*        view='clear'*/}
                    {/*        size='s'*/}
                    {/*        onClick={() => setLocationToConfirmRemove(row.id)}*/}
                    {/*/>*/}
                </Layout>
            )
        },
    ];

    return (
        <>
            <ListPage breadCrumbs={breadCrumbs} page={page} totalPages={totalPages} setPage={setPage}>
                <Layout className={styles.Actions} direction='row'>
                    <Layout className={styles.Actions__Filters} direction='row'>
                        <TextField placeholder="Найти..."
                                   onChange={({value}) => setSearchFieldValue(value)}
                                   value={searchFieldValue}
                                   withClearButton
                        />
                    </Layout>
                    <Layout className={styles.Actions__Buttons} direction='row'>
                        {/*<Button className={styles.ButtonAdd}*/}
                        {/*        view='secondary'*/}
                        {/*        label='Создать локацию'*/}
                        {/*        iconRight={IconAdd}*/}
                        {/*        onClick={() => setCreateModalIsOpen(true)}*/}
                        {/*/>*/}
                    </Layout>
                </Layout>
                <Confirm isOpen={!!locationToConfirmRemove}
                         onConfirm={() => handleRemoveConfirm()}
                         onClose={() => setLocationToConfirmRemove(undefined)}
                         title={`Вы действительно хотите удалить локацию ${locations.find((item) => item.id === locationToConfirmRemove)?.name}?`}
                />
                <Toast alert={alert} setAlert={setAlert}/>
                <Table rows={locations}
                       columns={columns}
                       onSortBy={handleSort}
                       emptyRowsPlaceholder={(loading ? <Loader className={styles.Loader}/> : undefined)}
                />
                <Popup isOpen={createModalIsOpen || editModalIsOpen} onClose={closePopup}>
                    <Form className={styles.Modal__Form}
                          mode={createModalIsOpen ? 'create' : editModalIsOpen ? 'edit' : 'view'}
                          label='локацию'
                          showActions={true}
                          onSubmit={createModalIsOpen ? handleCreate :
                              editModalIsOpen ? handleUpdate :
                                  () => console.log('Not supported.')}
                          onClose={closePopup}
                    >
                        <Layout direction='column'>
                            {
                                editModalIsOpen &&
                                <>
                                    <FieldRow label='UUID:'>
                                        <Text>{location.id}</Text>
                                    </FieldRow>
                                </>
                            }
                            <FieldRow label='Название:'>
                                <TextField value={location.name}
                                           required
                                           onChange={({value}) => setLocation({
                                               ...location, name: value!
                                           })}/>
                            </FieldRow>
                            {
                                editModalIsOpen &&
                                <>
                                    <FieldRow label='Создан в:'>
                                        <Text>{formatDateTime(location.created_at)}</Text>
                                    </FieldRow>
                                    <FieldRow label='Обновлен в:'>
                                        <Text>{formatDateTime(location.updated_at)}</Text>
                                    </FieldRow>
                                </>
                            }
                        </Layout>
                    </Form>
                </Popup>
            </ListPage>
        </>
    );
};

export {Locations};
