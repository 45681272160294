import * as React from "react";
import {FunctionComponent, ReactNode} from "react";
import {Button} from "@consta/uikit/Button";
import styles from './Popup.module.css';
import {Modal} from "@consta/uikit/Modal";
import {Text} from "@consta/uikit/Text";
import {IconClose} from "@consta/uikit/IconClose";

type Props = {
    title?: string,
    isOpen: boolean,
    onClose: () => void,
    children?: ReactNode;
    className?: string;
}

export const Popup: FunctionComponent<Props> = ({
                                                    title,
                                                    isOpen,
                                                    onClose,
                                                    children,
                                                    className
                                                }) => {

    return (
        <Modal isOpen={isOpen}
               hasOverlay
               onClickOutside={onClose}
               onEsc={onClose}
               className={className ?? styles.Popup}
        >
            {
                title &&
                <Text as="h1" size="l">{title}</Text>
            }
            {children}
            <Button className={styles.Popup__Close}
                    view='clear'
                    iconRight={IconClose}
                    onlyIcon
                    size='l'
                    onClick={onClose}
            />
        </Modal>
    );
}