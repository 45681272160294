import * as React from 'react';
import {FunctionComponent, useEffect} from 'react';
import {Layout} from "@consta/uikit/Layout";
import styles from './Toast.module.css';
import {Informer} from "@consta/uikit/Informer";
import {Button} from "@consta/uikit/Button";
import {IconClose} from "@consta/uikit/IconClose";
import {IconThumbUp} from "@consta/uikit/IconThumbUp";
import {IconAlert} from "@consta/uikit/IconAlert";

type ToastProps = {
    alert: { status: "alert" | "success" | "system" | "warning" | undefined, text: string };
    setAlert: (value: { status: "alert" | "success" | "system" | "warning" | undefined, text: string }) => void;
}

const Toast: FunctionComponent<ToastProps> = ({alert, setAlert}) => {
    // useEffect(() => {
    //     setTimeout(() => {
    //         hideToast();
    //     }, 3000)
    // }, [alert]);

    function hideToast() {
        setAlert({status: 'success', text: ''});
    }

    return (
        <>
            {
                alert && alert.text &&
                <Informer
                    className={styles.Alert}
                    status={alert.status}
                    view="filled"
                    icon={alert.status === 'success' ? IconThumbUp : alert.status === 'alert' ? IconAlert : IconAlert}
                    label={(
                        <Layout className={styles.Alert__Container}>
                            {alert.text}
                            <Button
                                onlyIcon
                                iconLeft={IconClose}
                                view='clear'
                                onClick={() => hideToast()}
                                size='s'
                            />
                        </Layout>
                    )}
                />
            }
        </>
    );
};

export {Toast};
