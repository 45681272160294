import * as React from 'react';
import {Fragment, FunctionComponent, useCallback, useEffect, useState} from 'react';
import {Layout} from "@consta/uikit/Layout";
import styles from './TaskList.module.css';
import {
    Company,
    TasksService,
    Task, SubmissionsService
} from "../../../api";
import {Table, TableColumn, TableTextFilter} from "@consta/uikit/Table";
import {
    displayTaskStatus,
    displayTaskStatusStyle,
    displayTimedelta,
    formatDateTime
} from "../../../utils/helpers";
import {Badge} from "@consta/uikit/Badge";
import {Text} from "@consta/uikit/Text";
import {Button} from "@consta/uikit/Button";
import {IconAdd} from "@consta/uikit/IconAdd";
import {TextField} from "@consta/uikit/TextField";
import {Switch} from "@consta/uikit/Switch";
import {ProtectedRouter} from "../../../Routers";
import {useLocation, useSearchParams} from "react-router-dom";
import {ListPage} from "../../../components/ListPage";
import {IconHome} from "@consta/uikit/IconHome";
import {Loader} from "@consta/uikit/Loader";
import {Sidebar} from "@consta/uikit/Sidebar";
import {TaskView} from "../../../components/TaskView";
import {FilterPageEnum} from "../../../utils/enum";
import {DatePicker} from "@consta/uikit/DatePicker";
import {useDebounce} from "@consta/uikit/useDebounce";
import {IconCancel} from "@consta/uikit/IconCancel";
import {IconEdit} from "@consta/uikit/IconEdit";
import {Popup} from "../../../components/Popup";
import {Toast} from "../../../components/Toast";
import {useAuth} from '../../../contexts/AuthContext';
import {IconCheck} from "@consta/uikit/IconCheck";

type cancelComment = {
    id: string;
    comment?: string;
};

const TaskList: FunctionComponent = () => {
        const useauth = useAuth().user.email
        const [searchParams, setSearchParams] = useSearchParams();
        const [errors, setErrors] = useState<string | undefined>(undefined);
        const [taskSidebar, setTaskSidebar] = useState(undefined);
        const [tasks, setTasks] = useState<Company[]>([]);
        const [loading, setLoading] = useState<boolean>(false);
        const [page, setPage] = useState<number>(searchParams.get(FilterPageEnum.Page) ? parseInt(searchParams.get(FilterPageEnum.Page)!) : 1);

        const [totalPages, setTotalPages] = useState<number>(1);

        // DATE FILTER
        const [startFilterByDate, setStartFilterByDate] = useState<any | null>(searchParams.get(FilterPageEnum.StartDate) ?? null);
        const [endFilterByDate, setEndFilterByDate] = useState<any | null>(searchParams.get(FilterPageEnum.EndDate) ?? null);
        const [searchByStartDate, setSearchByStartDate] = useState<string | null>(startFilterByDate);
        const [searchByEndDate, setSearchByEndDate] = useState<string | null>(endFilterByDate);
        const debounceSetStartDate = useDebounce(setSearchByStartDate, 500);
        const debounceSetEndDate = useDebounce(setSearchByEndDate, 500);
        const [startDateValue, setStartDateValue] = useState<Date | null>(null);
        const [endDateValue, setEndDateValue] = useState<Date | null>(null);
        const [isStartDateActive, setIsStartDateActive] = useState(false);
        const [isEndDateActive, setIsEndDateActive] = useState(false);
        //--

        // status filter
        const [statusCompleteFilter, setStatusCompleteFilter] = useState<boolean | undefined>(searchParams.get(FilterPageEnum.StatusComplete) !== 'false');
        // --

        // cancel task
        const [cancelTaskModalOpen, setCancelTaskModalOpen] = useState<boolean>(false);
        const [modalTaskId, setModalTaskId] = useState(null);
        const [cancelComment, setCancelComment] = useState<string | null>(null);
        // finish task
        const [finishTaskModalOpen, setFinishTaskModalOpen] = useState<boolean>(false);
        const [finishComment, setFinishComment] = useState<string | null>(null);
        let comment = '';
        const handleChangeCancel = ({value}: { value: string | null }) => {
            setCancelComment(value);
        }
        const handleChangeFinish = ({value}: { value: string | null }) => {
            setFinishComment(value);
        }
        const [taskData, setTaskData] = useState<Task>();
        const [alert, setAlert] = useState<any>({text: '', status: 'success'});
        const location = useLocation();
        // --

        const breadCrumbs = [
            {
                label: 'Главная',
                href: ProtectedRouter.SUBMISSION,
                icon: IconHome
            },
            {
                label: 'Задачи',
            },
        ];
        //FILTER DATE
        useEffect(() => {
            debounceSetStartDate(startFilterByDate);
        }, [debounceSetStartDate, startFilterByDate]);

        useEffect(() => {
            debounceSetEndDate(endFilterByDate);
        }, [debounceSetEndDate, endFilterByDate]);

        useEffect(() => {
            setStartFilterByDate(startDateValue?.toISOString() ?? null);
        }, [startDateValue]);

        useEffect(() => {
            setEndFilterByDate(endDateValue?.toISOString() ?? null);
        }, [endDateValue]);

        useEffect(() => {
            if (searchByStartDate) {
                searchParams.set(FilterPageEnum.Search, searchByStartDate)
            } else {
                searchParams.delete(FilterPageEnum.Search)
            }
            setSearchParams(searchParams);
        }, [searchByStartDate, searchParams, setSearchParams]);

        useEffect(() => {
            if (searchByEndDate) {
                searchParams.set(FilterPageEnum.Search, searchByEndDate)
            } else {
                searchParams.delete(FilterPageEnum.Search)
            }
            setSearchParams(searchParams);
        }, [searchByEndDate, searchParams, setSearchParams]);
        //--

        // // STATUS FILTER
        useEffect(() => {
            if (statusCompleteFilter) {
                searchParams.set(FilterPageEnum.StatusComplete, String(statusCompleteFilter));
            } else {
                searchParams.delete(FilterPageEnum.StatusComplete);
            }
            setSearchParams(searchParams);
        }, [searchParams, setSearchParams, statusCompleteFilter]);
        //--

        useEffect(() => {
            document.title = 'Список задач | SMA';
        }, []);


        useEffect(() => {
            setLoading(true);
            const fetchData = () => {
                TasksService.listTasks(searchParams.getAll("status"),
                    searchByStartDate ?? undefined,
                    searchByEndDate ?? undefined,
                    statusCompleteFilter ?? undefined,
                    page)
                    .then((data: any) => {
                        setTasks([...data.items]);
                        setTotalPages(Math.ceil(data.count / 50));
                    })
                    .catch((err) => setErrors(err))
                    .finally(() => setLoading(false));
            };
            fetchData();
            const intervalId = setInterval(fetchData, 1800000);
            return () => clearInterval(intervalId);
        }, [page, searchByEndDate, searchByStartDate, searchParams, statusCompleteFilter]);


        // CANCEL TASK by dispetcher
        const handleCancelTask =
            () => {
                if (modalTaskId && cancelComment) {
                    TasksService.cancelTask(modalTaskId, cancelComment)
                        .then((result) => {
                            setAlert({
                                status: 'success',
                                text: 'Задача успешно отменена.',
                            })
                            setCancelTaskModalOpen(false);
                            window.location.reload();
                        })
                } else {
                    setAlert({
                        status: 'alert',
                        text: 'Укажите причину отмены задачи!',
                    })
                }
            }
        // --

        // FINISH TASK by dispetcher
        const handleFinishTask =
            () => {
                if (modalTaskId && finishComment) {
                    TasksService.completeTask(modalTaskId, finishComment)
                        .then((result) => {
                            setAlert({
                                status: 'success',
                                text: 'Задача успешно завершена.',
                            })
                            setFinishTaskModalOpen(false);
                            window.location.reload();
                        })
                } else {
                    setAlert({
                        status: 'alert',
                        text: 'Укажите причину завершения заявки диспетчером:',
                    })
                }
            }

        // --


        const columns: TableColumn<any>[] = [
            {
                accessor: 'id',
                title: 'id',
                hidden: true
            },

            {
                title: 'Номер',
                accessor: 'name',
                sortable: true,
                width: 130,
                renderCell: ({id, name, status}) => (
                    <Layout className={styles.TableRow__TaskName}>
                        <Text view='link'
                              style={{cursor: 'pointer'}}
                              size='s'
                              onClick={() => setTaskSidebar(id)}>{name}</Text>
                        {status !== 'CANCELED' && useauth !== 'utt1@cud-hnt.ru' && (
<>
                            <Button iconLeft={IconCancel}
                                    onlyIcon
                                    view='clear'
                                    label='Отменить'
                                    size='s'
                                    onClick={() => {
                                        TasksService.getTask(id)
                                            .then((data: Task) => {
                                                setTaskData(data);
                                                setModalTaskId(id);
                                                setCancelTaskModalOpen(true);
                                            })
                                    }}
                            />
                            <Button iconLeft={IconCheck}
                                    onlyIcon
                                    view='clear'
                                    label='Выполнить'
                                    size='s'
                                    onClick={() => {
                                        TasksService.getTask(id)
                                            .then((data: Task) => {
                                                setTaskData(data);
                                                setModalTaskId(id);
                                                setFinishTaskModalOpen(true);
                                            })
                                    }}
                            />
                            </>
                        )}
                    </Layout>
                )
            },
            {
                title: 'Статус',
                accessor: 'status',
                sortable: true,
                width: 160,
                renderCell: ({status}) => (
                    <Layout className={styles.TableRow__TaskName}>
                        <Badge
                            size='s'
                            status={displayTaskStatusStyle(status)}
                            label={displayTaskStatus(status)}
                        />
                    </Layout>
                ),
            },
            {
                title: 'Куст',
                accessor: 'bush',
                sortable: true,
                renderCell: ({wells}) => <b>{wells?.bush?.name}</b>
            },
            {
                title: 'Скв.',
                accessor: 'wells',
                sortable: true,
                renderCell: ({wells}) => <b>{wells?.name}</b>
            },
            {
                title: 'Бригада',
                accessor: 'brigade',
                sortable: true,
                renderCell: ({brigade}) => <b>{brigade?.name}</b>
            },
            {
                title: 'Транспорт',
                accessor: 'transport',
                sortable: true,
                width: 160,
                renderCell: ({transport, assigned_to}) => (
                    <Layout direction='column'>
                        <Text size='s'><b>{`${transport.type.name} [${transport.reg_number}]`}</b></Text>
                        {
                            assigned_to &&
                            <Text size='s'>{assigned_to?.fio}</Text>
                        }
                    </Layout>
                )
            },
            {
                title: 'Вид работ',
                accessor: 'worktype',
                sortable: true,
                width: 130,
                renderCell: ({worktype}) => worktype?.name
            },
            {
                title: 'Описание',
                accessor: 'about',
                sortable: true,
                width: 160,
            },
            // {
            //     title: 'Движение',
            //     columns: [
            //         {
            //             title: 'План',
            //             width: 75,
            //             accessor: 'move_start',
            //             sortable: true,
            //             renderCell: ({move_start}) => (<Text size='xs'>{formatDateTime(move_start, '-')}</Text>)
            //         },
            //         {
            //             title: 'Факт',
            //             width: 75,
            //             accessor: 'fact_move_start',
            //             sortable: true,
            //             renderCell: ({fact_move_start}) => (<Text size='xs'>{formatDateTime(fact_move_start, '-')}</Text>)
            //         },
            //         // {
            //         //     title: 'Факт.конец',
            //         //     width: 75,
            //         //     accessor: 'fact_move_end',
            //         //     sortable: true,
            //         //     renderCell: ({fact_move_end}) => (<Text size='xs'>{formatDateTime(fact_move_end, '-')}</Text>)
            //         // },
            //     ],
            // },
            {
                title: 'Работа начало',
                columns: [
                    {
                        title: 'План',
                        // accessor: 'start',
                        accessor: 'plan_start',
                        sortable: true,
                        width: 75,
                        renderCell: ({start}) => (<Text size='xs'>{formatDateTime(start, '-')}</Text>)
                    },
                    {
                        title: 'Факт',
                        // accessor: 'start',
                        accessor: 'fact_start',
                        sortable: true,
                        width: 75,
                        renderCell: ({fact_start}) => (<Text size='xs'>{formatDateTime(fact_start, '-')}</Text>)
                    },
                ]
            },
            {
                title: 'Работа конец',
                columns: [
                    {
                        title: 'План',
                        width: 75,
                        accessor: 'end',
                        sortable: true,
                        renderCell: ({end}) => (<Text size='xs'>{formatDateTime(end, '-')}</Text>)
                    },
                    {
                        title: 'Факт',
                        width: 75,
                        accessor: 'fact_end',
                        sortable: true,
                        renderCell: ({fact_end}) => (<Text size='xs'>{formatDateTime(fact_end, '-')}</Text>)
                    },
                ]
            },
            {
                title: 'Прод-ть',
                width: 100,
                accessor: 'expected_duration',
                renderCell: ({expected_duration}) => (<Text size='xs'>{displayTimedelta(expected_duration)}</Text>)
            },
            {
                title: 'Обновлен',
                sortable: true,
                accessor: 'updated_at',
                renderCell: ({updated_at}) => (<Text size='xs'>{formatDateTime(updated_at)}</Text>)
            },
        ];

        const filters = [
            {
                id: 'status',
                name: 'Статус: ',
                field: 'status',
                filterer: (
                    cellValue: string,
                    filterValues: Array<{ value: string; name: string }>,
                ) => {
                    return filterValues.some((filterValue) => filterValue && filterValue.value === cellValue,);
                },
                component: {
                    name: TableTextFilter,
                    props: {
                        items: [
                            {name: 'Назначен водитель', value: 'ASSIGNED'},
                            {name: 'Не назначен', value: 'UNASSIGNED'},
                            {name: 'Принят', value: 'ACCEPTED'},
                            {name: 'Начал выполнение', value: 'STARTED'},
                            {name: 'Завершено', value: 'FINISH'},
                            {name: 'Прибыл на объект', value: 'ARRIVED'},
                            {name: 'Отклонена водителем', value: 'DECLINED'},
                            {name: 'Отменен', value: 'CANCELED'},
                            {name: 'В архиве', value: 'ARCHIVED'},
                            {name: 'Пересменка', value: 'RESMENA'},
                        ],
                    },
                },
            },

        ];

        return (
            <ListPage breadCrumbs={breadCrumbs} page={page} totalPages={totalPages} setPage={setPage}>
                <Layout className={styles.Actions}>
                    <Layout className={styles.ActionsFilters} direction='row'>
                        <DatePicker
                            value={isStartDateActive ? startDateValue : endDateValue}

                            // caption={
                            //     isStartDateActive
                            //         ? 'Начало работ'
                            //         : isEndDateActive
                            //             ? 'Конец работ'
                            //             : ''
                            // }
                            type="date"
                            format="yyyy-MM-dd"
                            onChange={({value}) => {
                                setIsStartDateActive(true);
                                setIsEndDateActive(false);
                                setEndDateValue(null);
                                setStartDateValue(value);
                                setStartFilterByDate(value?.toISOString() ?? null);
                                // кнопки начало конец
                                // if (isStartDateActive) {
                                //     setEndDateValue(null);
                                //     setStartDateValue(value);
                                //     setStartFilterByDate(value?.toISOString() ?? null);
                                // } else if (isEndDateActive) {
                                //     setStartDateValue(null);
                                //     setEndDateValue(value);
                                //     setEndFilterByDate(value?.toISOString() ?? null);
                                // }
                            }}
                            // renderAdditionalControls={() => (
                            //     <Fragment>
                            //         <div style={{display: 'flex', justifyContent: 'space-between', gap: '8px'}}>
                            //             <Button className={styles.DatePickerButton}
                            //                     label="Начало"
                            //                     onClick={() => {
                            //                         setIsStartDateActive(true);
                            //                         setIsEndDateActive(false);
                            //                     }}
                            //             />
                            //             <Button className={styles.DatePickerButton}
                            //                     label="Конец"
                            //                     onClick={() => {
                            //                         setIsStartDateActive(false);
                            //                         setIsEndDateActive(true);
                            //                     }}
                            //             />
                            //         </div>
                            //     </Fragment>
                            // )}
                        />
                        <Switch checked={statusCompleteFilter}
                                onChange={() => setStatusCompleteFilter(!statusCompleteFilter)}
                                label='Скрыть завершенные'
                        />

                    </Layout>
                    {/*<Layout className={styles.ActionsButtons} direction='row'>*/}
                    {/*    <Button className={styles.ButtonAdd}*/}
                    {/*            view='secondary'*/}
                    {/*            label='Создать задачу'*/}
                    {/*            iconLeft={IconAdd}*/}
                    {/*            onClick={() => navigate(ProtectedRouter.TASKS_CREATE)}*/}
                    {/*    />*/}
                    {/*</Layout>*/}
                </Layout>
                <Table className={styles.Table}
                       columns={columns}
                       filters={filters}
                       stickyHeader={true}
                       rows={tasks}
                       size='s'
                       isResizable
                       emptyRowsPlaceholder={(loading ? <Loader className={styles.Loader}/> : undefined)}
                />

                <Sidebar isOpen={!!taskSidebar}
                         position='left'
                         size='2/3'
                         onEsc={() => setTaskSidebar(undefined)}
                         onClickOutside={() => setTaskSidebar(undefined)}
                >
                    <TaskView task_id={taskSidebar}/>
                </Sidebar>

                {/*cancel task*/}
                <Popup className={styles.Modal__Container}
                       isOpen={cancelTaskModalOpen}
                       onClose={() => setCancelTaskModalOpen(false)}
                >
                    <>
                        <Layout className={styles.Modal__Content__Head}>
                            <Text

                                align="left"
                                size="2xl"
                                // transform="uppercase"
                                view="primary"
                                weight="bold"
                            >Отмена задачи №{taskData?.name}</Text>
                            {/*<Text size="2xl" weight="bold" align="left">Бригада {taskData?.brigade}</Text>*/}
                        </Layout>
                        <Layout className={styles.Modal__Content__Text}>
                            <TextField
                                onChange={handleChangeCancel}
                                value={cancelComment}
                                type="textarea"
                                cols={200}
                                rows={3}
                                placeholder="Причина отмены..."
                                leftSide={IconEdit}
                            />
                        </Layout>

                        <Button className={styles.Modal__Content__Text}
                                iconLeft={IconCancel}
                                label='Отменить задачу'
                                view="primary"
                                onClick={handleCancelTask}
                        />
                    </>
                </Popup>

                {/*finish task*/}
                <Popup className={styles.Modal__Container}
                       isOpen={finishTaskModalOpen}
                       onClose={() => setFinishTaskModalOpen(false)}
                >
                    <>
                        <Layout className={styles.Modal__Content__Head}>
                            <Text

                                align="left"
                                size="2xl"
                                // transform="uppercase"
                                view="primary"
                                weight="bold"
                            >Завершение задачи №{taskData?.name}</Text>
                            {/*<Text size="2xl" weight="bold" align="left">Бригада {taskData?.brigade}</Text>*/}
                        </Layout>
                        <Layout className={styles.Modal__Content__Text}>
                            <TextField
                                onChange={handleChangeFinish}
                                value={finishComment}
                                type="textarea"
                                cols={200}
                                rows={3}
                                placeholder="Причина завершения диспетчером..."
                                leftSide={IconEdit}
                            />
                        </Layout>

                        <Button className={styles.Modal__Content__Text}
                                iconLeft={IconCancel}
                                label='Завершить задачу'
                                view="primary"
                                onClick={handleFinishTask}
                        />
                    </>
                </Popup>
                <Toast alert={alert} setAlert={setAlert}/>
            </ListPage>
        );
    }
;

export {TaskList};
