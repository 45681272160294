import * as React from 'react';
import {FunctionComponent, useEffect, useState} from 'react';
import {Layout} from "@consta/uikit/Layout";
import styles from './TaskView.module.css';
import {Text} from "@consta/uikit/Text";
import {ApiError, TaskHistoryInline, TasksService, TaskWithHistory} from "../../api";
import {NotFound} from "../NotFound/NotFound";
import {
    displayTaskStatus,
    displayTaskStatusStyle,
    displayTimedelta,
    formatDateTime,
    secondsToTimedeltaString
} from "../../utils/helpers";
import {Badge} from "@consta/uikit/Badge";
import {TableColumn} from "@consta/uikit/Table";
import {ProgressStepBar} from "@consta/uikit/ProgressStepBar";
import {ProgressLine} from "@consta/uikit/ProgressLine";
import {FieldRow} from "../FieldRow";

type TaskViewProps = {
    task_id?: string;
}

const TaskView: FunctionComponent<TaskViewProps> = ({task_id}) => {

        const [task, setTask] = useState<TaskWithHistory>();
        const [loading, setLoading] = useState<boolean>(true);
        const [error, setError] = useState<any>();

        const errorHandler = (err: ApiError, callback?: (err: ApiError) => void) => {
            console.log(JSON.stringify(err));
            if (err.status === 404) {
                setError(404);
            }
            if (err.body.detail) {
                console.log(err.body.detail);
                setError(err.body.detail)
            }
            if (callback) {
                callback(err);
            }
        };
        useEffect(() => {
            if (task_id) {
                TasksService.getTaskWithHistory(task_id)
                    .then((data: TaskWithHistory) => setTask(data))
                    .catch(errorHandler)
                    .finally(() => setLoading(false));
            }
        }, [task_id]);


        if (error === 404) {
            return (
                <NotFound/>
            );
        }

        if (loading) {
            return (
                <ProgressLine/>
            );
        }
        if (task) {
            return (
                <Layout className={styles.Container} direction='column'>
                    <Layout className={styles.FieldSet} direction='column'>
                        <Text as='h1' size='s'>Задание №{task?.name}</Text>
                        <FieldRow label='Куст:' size='s'><Text size='s'>{task.wells?.bush?.name}</Text></FieldRow>
                        <FieldRow label='Скважина:' size='s'><Text size='s'>{task.wells?.name}</Text></FieldRow>
                        <FieldRow label='Бригада:' size='s'><Text size='s'>{task.brigade?.name}</Text></FieldRow>
                        <FieldRow label='Описание:' size='s'><Text size='s'>{task.about}</Text></FieldRow>
                        {/*<FieldRow label='Локация:' size='s'><Text size='s'>{task.location.name}</Text></FieldRow>*/}
                        <FieldRow label='Статус:' size='s'>
                            <Badge
                                size='s'
                                status={displayTaskStatusStyle(task?.status)}
                                label={displayTaskStatus(task?.status)}
                            />
                        </FieldRow>
                        <FieldRow label='Единица транспорта:' size='s'><Text
                            size='s'>{task.transport.reg_number}</Text>
                        </FieldRow>
                        <FieldRow label='Назначен на:' size='s'><Text
                            size='s'
                        >
                            {task.assigned_to ? `${task.assigned_to?.fio} (${task.assigned_to?.company?.name}, ${task.assigned_to?.phone})` : '—'}
                        </Text>
                        </FieldRow>
                        <Layout className={styles.FieldSet__Row}>
                            <Text className={styles.FieldSet__Row__Label} view='secondary' size='s'>Создана в:</Text>
                            <Text size='s'>{formatDateTime(task.created_at) ?? '—'}</Text>
                        </Layout>

                        <Layout className={`${styles.FieldSet__Row} ${styles.FieldSet__Row__Multiline}`}
                                direction='column'>
                            <Text className={styles.FieldSet__Row__Label} view='secondary' size='s'>Начало
                                движения</Text>
                            <Layout>
                                <Text className={styles.FieldSet__Row__Label} view='secondary' size='s'>План:</Text>
                                <Text size='s'>{formatDateTime(task.move_start) ?? '—'}</Text>
                            </Layout>
                            <Layout>
                                <Text className={styles.FieldSet__Row__Label} view='secondary' size='s'>Факт:</Text>
                                <Layout className={styles.Delay__Container}>
                                    {
                                        task.fact_move_start &&
                                        <Text size='s'
                                              view={task.move_start_delay ? 'alert' : undefined}
                                        >{formatDateTime(task.fact_move_start) ?? '—'}
                                        </Text>
                                    }
                                    {
                                        task.move_start_delay &&
                                        <Text view='alert'
                                              size='s'>{`задержка ${secondsToTimedeltaString(task.move_start_delay)}`}</Text>
                                    }
                                </Layout>
                            </Layout>
                        </Layout>
                        <Layout className={`${styles.FieldSet__Row} ${styles.FieldSet__Row__Multiline}`}
                                direction='column'>
                            <Text className={styles.FieldSet__Row__Label} view='secondary' size='s'>Конец
                                движения</Text>
                            <Layout>
                                <Text className={styles.FieldSet__Row__Label} view='secondary' size='s'>Факт:</Text>
                                <Text size='s'>{formatDateTime(task.fact_start) ?? '—'}</Text>
                            </Layout>
                        </Layout>
                        <Layout className={`${styles.FieldSet__Row} ${styles.FieldSet__Row__Multiline}`}
                                direction='column'>
                            <Text className={styles.FieldSet__Row__Label} view='secondary' size='s'>Начало работ</Text>
                            <Layout>
                                <Text className={styles.FieldSet__Row__Label} view='secondary' size='s'>План:</Text>
                                <Text size='s'>{formatDateTime(task.start) ?? '—'}</Text>
                            </Layout>
                            <Layout>
                                <Text className={styles.FieldSet__Row__Label} view='secondary' size='s'>Факт:</Text>
                                <Layout className={styles.Delay__Container}>
                                    {
                                        task.fact_start &&
                                        <Text size='s'
                                              view={task.start_delay ? 'alert' : undefined}
                                        >{formatDateTime(task.fact_start) ?? '—'}
                                        </Text>
                                    }
                                    {
                                        task.start_delay &&
                                        <Text view='alert'
                                              size='s'>{`задержка ${secondsToTimedeltaString(task.start_delay)}`}</Text>
                                    }
                                </Layout>
                            </Layout>
                            <Layout>
                                <Text className={styles.FieldSet__Row__Label} view='secondary'
                                      size='s'>Длительность:</Text>
                                <Text size='s'>{displayTimedelta(task.expected_duration) ?? '—'}</Text>
                            </Layout>
                        </Layout>
                        <Layout className={`${styles.FieldSet__Row} ${styles.FieldSet__Row__Multiline}`}
                                direction='column'>
                            <Text className={styles.FieldSet__Row__Label} view='secondary' size='s'>Конец работ</Text>
                            <Layout>
                                <Text className={styles.FieldSet__Row__Label} view='secondary' size='s'>План:</Text>
                                <Text size='s'>{formatDateTime(task.end) ?? '—'}</Text>
                            </Layout>
                            <Layout>
                                <Text className={styles.FieldSet__Row__Label} view='secondary' size='s'>Факт:</Text>
                                <Layout className={styles.Delay__Container}>
                                    {
                                        task.fact_end &&
                                        <Text size='s'
                                              view={task.end_delay ? 'alert' : undefined}>{formatDateTime(task.fact_end) ?? '—'}</Text>
                                    }
                                    {
                                        task.end_delay &&
                                        <Text view='alert'
                                              size='s'>{`задержка ${secondsToTimedeltaString(task.end_delay)}`}</Text>
                                    }
                                </Layout>
                            </Layout>
                        </Layout>
                        {
                            task?.history &&
                            <Layout className={styles.History__Container} direction='column'>
                                <Text as='h2'>История</Text>
                                 {task.status !== "RESMENA" && (
                                <ProgressStepBar steps={task.history?.map((task: TaskHistoryInline, index: number) => ({
                                    label: `${formatDateTime(task.created_at)}: ${displayTaskStatus(task.status)}`,
                                    status: displayTaskStatusStyle(task.status),
                                    lineStatus: displayTaskStatusStyle(task.status),
                                    content: (task.assigned_to && <Text className={styles.History__Text}
                                                                        size='s'
                                    >{`${task.assigned_to?.fio}, ${task.assigned_to?.company?.name}, ${task.assigned_to?.phone}`}</Text>),
                                }))}
                                                 direction='vertical'
                                                 getItemLabel={(item: any) => item.label}
                                                 activeStepIndex={task?.history?.length - 1 ?? 0}
                                />
                                       )}
                            </Layout>
                        }

                    </Layout>
                    {
                        error &&
                        <Text view='alert'>{error}</Text>
                    }
                </Layout>
            );
        }

        return <></>;
    }
;

export {TaskView};
