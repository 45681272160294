import * as React from 'react';
import {FunctionComponent, useState} from 'react';
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import styles from './Dashboard.module.css';
import {Layout} from "@consta/uikit/Layout";
import {Tabs} from "@consta/uikit/Tabs";
import {useDashboardMenuItems} from "../../hooks/useDashboardMenuItems";
import {Button} from "@consta/uikit/Button";
import {IconArrowLeft} from "@consta/uikit/IconArrowLeft";
import {IconArrowRight} from "@consta/uikit/IconArrowRight";
import {Card} from "@consta/uikit/Card";

const Dashboard: FunctionComponent = () => {
    const items = useDashboardMenuItems();
    const location = useLocation();
    const [value, setValue] = useState(items.find((item) => item.href === location.pathname) ?? items[0]);
    const navigate = useNavigate();
    const [isSideMenuOpen, setIsSideMenuOpen] = useState<boolean>(true);

    return (
        <Layout>
            {
                isSideMenuOpen ?
                    <Card className={styles.SubMenu__Container}>
                        <Tabs
                            className={styles.SubMenu}
                            value={value}
                            onChange={({value}) => {
                                setValue(value);
                                navigate(value.href!)
                            }}
                            items={items}
                            getItemLabel={(item) => item.label!}
                            view='clear'
                            linePosition='right'
                        />
                        <Button className={styles.SubMenu__Container__HideButton}
                                label="Скрыть меню"
                                view="clear"
                                iconLeft={IconArrowLeft}
                                onlyIcon
                                onClick={() => setIsSideMenuOpen(false)}
                        />
                    </Card>
                    : (

                        <Button className={styles.SubMenu__Container__HideButton}
                                label="Открыть меню"
                                view="clear"
                                iconLeft={IconArrowRight}
                                onlyIcon
                                onClick={() => setIsSideMenuOpen(true)}
                        />
                    )
            }
            <Layout className={styles.Content}>
                <Outlet/>
            </Layout>
        </Layout>
    );
};

export {Dashboard};
