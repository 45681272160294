import * as React from 'react';
import {FunctionComponent, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {
    ApiError, BushSchema,
    Submission, SubmissionCreate,
    SubmissionsService,
    SubmissionTransportTypeInline,
    SubmissionTransportTypeInlineUpdate,
    TaskBasicInline,
    TaskCreate,
    TransportsService,
    TransportType,
    TransportWithTasks, WellsSchema, WellsService, WorkTypeSchema, WorktypeService
} from "../../../api";
import {ProtectedRouter, PublicRouter} from "../../../Routers";
import {Text} from "@consta/uikit/Text";
import {Layout} from "@consta/uikit/Layout";
import styles from './SubmissionProcess.module.css';
import {Card} from "@consta/uikit/Card";
import {Badge} from "@consta/uikit/Badge";
import {
    dateToTimedelta,
    displaySubmissionTransportTypeStatus,
    displaySubmissionTransportTypeStatusStyle,
    formatDateTime,
    getTimedeltaNumber,
    timerStringToDate,
    zeroPad
} from "../../../utils/helpers";
import {DatePicker} from "@consta/uikit/DatePicker";
import {Button} from "@consta/uikit/Button";
import {IconCheck} from "@consta/uikit/IconCheck";
import {IconCancel} from "@consta/uikit/IconCancel";
import {Table, TableColumn} from "@consta/uikit/Table";
import {TextField} from "@consta/uikit/TextField";
import {Toast} from "../../../components/Toast";
import {Sidebar} from "@consta/uikit/Sidebar";
import {TaskView} from "../../../components/TaskView";
import {IconWatch} from "@consta/uikit/IconWatch";
import {Breadcrumbs} from "@consta/uikit/Breadcrumbs";
import {IconHome} from "@consta/uikit/IconHome";
import {IconTrash} from "@consta/uikit/IconTrash";
import {Confirm} from "../../../components/Confirm";
import {Loading} from "../../../components/Loading";
import {Combobox} from "@consta/uikit/Combobox";

type TransportTypeInlineType = {
    id: string;
    start?: Date;
    comment?: string;
    task?: TaskBasicInline;
    status?: string;
    type: TransportType;
    duration?: Date;
    road_duration?: Date;
    reg_number?: string;
    worktype?: WorkTypeSchema;
};


const SubmissionProcess: FunctionComponent = () => {
    const [submissionCreate, setSubmissionCreate] = useState<SubmissionCreate>({
        brigade_id: "",
        date: "",
        oildeb: "",
        // location_id: "",
        wells_id: "",
        transports: []
    });
    let [newWellsID, setNewWellsID] = useState<string | undefined>(undefined);
    const [wells, setWells] = useState<WellsSchema[]>([]);
    const [selectedBushId, setSelectedBushId] = useState<string | null>(null);
    const filteredWells = wells.filter(item => item?.bush?.id === selectedBushId);
    const uniqueWells = [...new Set(wells.map(item => item?.bush?.name))];
    const [selectedBushName, setSelectedBushName] = useState<string | null>(null);

    const [bush, setBush] = useState<BushSchema[]>([]);

    const [alert, setAlert] = useState<any>({text: '', status: 'success'});
    const [errors, setErrors] = useState<any>({});
    const [transportTypes, setTransportTypes] = useState<TransportTypeInlineType[]>([]);
    const [transports, setTransports] = useState<TransportWithTasks[]>([]);
    const [confirmRemoveSubmissionOpen, setConfirmRemoveSubmissionOpen] = useState<boolean>(false);
    const [taskSidebar, setTaskSidebar] = useState(undefined);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [submission, setSubmission] = useState<Submission>();
    const {submissionId} = useParams();
    const navigate = useNavigate();

    const [worktype, setWorkType] = useState<WorkTypeSchema[]>([]);

    // минимальная дата начала работ
    const now = new Date();
    const timezoneOffset = now.getTimezoneOffset() * 60000;
    const timezoneOffsetPlus5 = timezoneOffset + (5 * 60 * 60 * 1000); // добавляем 5 часов
    const minDate = new Date(now.getTime() - timezoneOffsetPlus5);

    const [techNumberStatus, setTechNumberStatus] = useState<"success" | "alert" | "warning" | undefined>(undefined);
    const [toRoadStatus, setToRoadStatus] = useState<"success" | "alert" | "warning" | undefined>(undefined);

    const breadCrumbs = [
        {
            label: 'Главная',
            href: ProtectedRouter.SUBMISSION,
            icon: IconHome
        },
        {
            label: 'Заявки',
            href: ProtectedRouter.SUBMISSION
        },
        {
            label: `Заявка №${submission?.name}`
        },
    ];

    useEffect(() => {
        document.title = `Обработка заявки ${submission?.name} | SMA`;
    }, [submission]);

    useEffect(() => {
        if (submissionId && !submitted) {
            setLoading(true);
            SubmissionsService.getSubmission(submissionId)
                .then((data: Submission) => {
                    setSubmission(data);
                    if (data.transports) {
                        setTransportTypes([...data.transports.map((item: SubmissionTransportTypeInline) => (
                            {
                                ...item,
                                start: new Date(Date.parse(item.start!)),
                                duration: timerStringToDate(item.duration)
                            }
                        ))]);
                    }
                })
                .catch(errorHandler)
                .finally(() => setLoading(false));
        }
        setSubmitted(false);
    }, [submitted]);


    const errorHandler = (err: ApiError) => {
        console.log(JSON.stringify(err));
        let text = JSON.stringify(err);
        if (err.status === 404) {
            navigate(PublicRouter.NOT_FOUND);
        }
        if (err.status === 409) {
            text = err.body.detail;
        }
        if (typeof err?.body?.detail === "string") {
            text = err.body.detail;
        }
        if (Array.isArray(err?.body?.detail)) {
            text = err.body.detail.map((item: any) => item.msg).join('\n');
        }
        setAlert({status: 'alert', text})
    };

    useEffect(() => {
        TransportsService.listTransports()
            .then((data: any) => setTransports([...data]))
            .catch(errorHandler);
    }, []);

    function getTransportCreateListValueByUuid(name: string, index: string | number, value: any) {
        setTransportTypes(
            transportTypes.map((item: any) => item.id === index ? {
                ...item,
                [name]: value,
            } : item)
        );
    }

    // ид строки для алерта
    const [selectedRowId, setSelectedRowId] = useState(null);
    const submitHandler = (task: any) => {
        // проверки алерт
        setSelectedRowId(task.id);
        const roadTime = dateToTimedelta(task.road_duration);
        if (!task.transport?.reg_number) {
            setAlert({status: 'alert', text: 'Поле "ЕД.ТЕХНИКИ" является обязательным. Укажите гос.номер.'});
            setTechNumberStatus('alert')
            return;
        } else {
            setTechNumberStatus('success')
        }
        if (!roadTime) {
            setAlert({
                status: 'alert',
                text: 'Поле "НА ДОРОГУ" является обязательным. Укажите ориентировочное время на дорогу до обьекта.'
            });
            setToRoadStatus('alert')
            return;
        } else {
            setToRoadStatus('success')
        }
        // --
        const payload: TaskCreate = {
            transport_id: transports?.find(item => item.reg_number === task.transport.reg_number)?.id!,
            about: task.comment,
            // location_id: submission?.location?.id!,
            worktype_id: task?.worktype?.id!,
            // wells_id: submission?.wells?.id!,
            wells_id: submission?.wells?.bush?.name === '_' ? newWellsID : submission?.wells?.id!,
            brigade_id: submission?.brigade?.id,
            start: task.start.toISOString(),
            move_start: task.start && roadTime ? new Date(task.start - roadTime!)?.toISOString() : undefined,
            expected_duration: getTimedeltaNumber(task.duration) ?? undefined,
            name: `${submission?.name}/${task.pos}`
        };
        SubmissionsService.createTask(task.id, payload)
            .then((data: SubmissionTransportTypeInline) => {
                setTransportTypes(
                    transportTypes.map((item: any) => item.id === data.id ? {
                        ...data,
                    } : item));
            })
            .then(() => {
                setAlert({status: 'success', text: 'Задача успешно создана.'});
                setErrors({});
            })
            .catch((err: ApiError) => {
                console.log(JSON.stringify(err));
                if (err?.statusText === "Conflict") {
                    setAlert({status: 'alert', text: err?.body.detail});
                }
                if (err?.body?.detail instanceof String) {
                    setAlert({status: 'alert', text: err.body.detail});
                }
                if (Array.isArray(err?.body?.detail)) {
                    // text = err.body.detail.map((item: any) => item.msg).join('\n');
                    // todo
                }
            });
    }

    const updateHandler = (transport: any, status: any) => {
        const payload: SubmissionTransportTypeInlineUpdate = {
            start: transport.start.toISOString(),
            duration: transport.duration ? getTimedeltaNumber(transport.duration) : undefined,
            comment: transport.comment,
            status: status
        };
        SubmissionsService.updateSubmissionTransportType(transport.id, payload)
            .then((data: SubmissionTransportTypeInline) => {
                console.log('data', data)
                setTransportTypes(
                    transportTypes.map((item: any) => item.id === data.id ? {
                        ...data,
                        start: new Date(Date.parse(data.start!)),
                        duration: timerStringToDate(data.duration)
                    } : item));
            })
            .then(() => {
                setAlert({status: 'success', text: 'Транспорт изменен.'})
                setSubmitted(true);
            })
            .catch(errorHandler);
    }

    const removeSubmission = () => {
        if (submissionId) {
            SubmissionsService
                .deleteSubmission(submissionId)
                .then((data: any) => {
                    if (data.success) {
                        navigate(ProtectedRouter.SUBMISSION,
                            {state: {alert: {name: submission?.name, action: 'removed'}}})
                    }
                })
                .catch(errorHandler);
        }
    }

    // const handleChangeDate = (value?:string) => {
    //     // Обновляем состояние компонента или выполняем другую обработку
    //     console.log(value); // Выводим значение в консоль
    // }

    useEffect(() => {
        WorktypeService.listWorktype()
            .then((data) => setWorkType([...data]))
            .catch(errorHandler);
    }, []);


    useEffect(() => {
        WellsService.listWells()
            .then((data) => setWells([...data]))
            .catch(errorHandler);
    }, []);

    useEffect(() => {
        WellsService.listBush()
            .then((data) => setBush([...data]))
            .catch(errorHandler);
    }, []);


    useEffect(() => {
        setSelectedBushName(wells.find(item => item.id === submissionCreate?.wells_id)?.bush?.name ?? null);
    }, [submissionCreate?.wells_id]);
    console.log("fdgdfg")
    const columns: TableColumn<any>[] = [
        {
            accessor: 'id',
            title: 'id',
            hidden: true
        },
        {
            accessor: 'pos',
            title: '№',
        },
        {
            accessor: 'status',
            title: 'Статус',
            width: 85,
            // sortable: true,
            renderCell: ({status}) => (
                <Badge status={displaySubmissionTransportTypeStatusStyle(status)}
                       label={displaySubmissionTransportTypeStatus(status)}
                       size='s'
                />
            )
        },
        {
            accessor: 'type',
            title: 'Тип техники',
            width: 100,
            renderCell: row => row.type?.name
        },

        {
            accessor: 'transport',
            title: 'Ед. техники',
            renderCell: (row) => (
                row.task ? row.task.transport.reg_number :
                    <Combobox
                        id={`edTech-${row.id}`}
                        size='s'
                        className={styles.FieldSet__Col}
                        placeholder="Введите номер"
                        items={transports.filter((item) => item.type.id === row.type?.id)
                            .sort((a, b) => a.reg_number.localeCompare(b.reg_number))}
                        onChange={({value}) => getTransportCreateListValueByUuid('transport', row.id, value)}
                        status={row.id === selectedRowId ? techNumberStatus : undefined}
                        getItemLabel={item => (`${item.reg_number}${item.status?item.tasks?.length === 0?'(на линии)':'(есть задача)':''}`)}
                        getItemKey={item => item.id}
                        value={row.transport}
                    />
            )
        },
        {
            accessor: 'worktype',
            title: 'Вид работ',
            width: 180,
            renderCell: (row) => (
                row.task ? row.task.worktype.name :
                    <Combobox
                        id={`worktype-${row.id}`}
                        size="s"
                        className={styles.FieldSet__Col}
                        placeholder="Введите номер"
                        items={worktype
                            .filter(item => item.type.id === row?.type?.id)
                            .sort((a, b) => a.name.localeCompare(b.name))
                        }
                        onChange={({value}) => getTransportCreateListValueByUuid('worktype', row.id, value)}
                        getItemLabel={item => item.name} //
                        getItemKey={item => item.id ? item.id : ''}
                        value={row.worktype}
                    />
            )
        },
        {
            accessor: 'road_duration',
            title: 'На дорогу',
            renderCell: (row) => {
                if (row.task) {
                    const dt = new Date(new Date(Date.parse(row.task?.start)).valueOf() - new Date(Date.parse(row.task?.move_start)).valueOf());
                    return `${zeroPad(dt.getUTCHours(), 2)}:${zeroPad(dt.getUTCMinutes(), 2)}`;
                }
                return (
                    <DatePicker
                        className={styles.FieldSet__TimePicker}
                        type="time"
                        size='s'
                        multiplicityMinutes={5}
                        multiplicitySeconds={0}
                        placeholder='ЧЧ:ММ'
                        value={row?.road_duration}
                        status={row.id === selectedRowId ? toRoadStatus : undefined}
                        format='HH:mm'
                        withClearButton
                        onChange={({value}) => getTransportCreateListValueByUuid('road_duration', row.id, value)}
                    />
                );
            }
        },
        {
            accessor: 'start',
            title: 'Время начала',
            renderCell: (row) => (
                row.task ? formatDateTime(row.task.start) :
                    <DatePicker
                                id={`start-${row.id}`}
                                className={styles.FieldSet__DateTimePicker}
                                type="date-time"
                                size='s'
                                placeholder='ДД.ММ.ГГГГ ЧЧ.ММ'
                                multiplicityMinutes={10}
                                minDate={minDate}
                                format='dd.MM.yyyy HH:mm'
                                value={row.start}
                                onChange={({value}) =>
                                    getTransportCreateListValueByUuid('start', row.id, value)
                                }
                    />
            )
        },
        {
            accessor: 'duration',
            title: 'Прод-ть',
            renderCell: (row) => (
                row.task ? timerStringToDate(row.task.expected_duration)?.toLocaleTimeString('default', {
                        hour: "2-digit",
                        minute: "2-digit"
                    }) :
                    <DatePicker
                        id={`duration-${row.id}`}
                        className={styles.FieldSet__TimePicker}
                        type="time"
                        size='s'
                        multiplicityMinutes={10}
                        multiplicitySeconds={0}
                        placeholder='ЧЧ:ММ'
                        value={row.duration}
                        format='HH:mm'
                        onChange={({value}) => getTransportCreateListValueByUuid('duration', row.id, value)}
                    />
            )
        },
        {
            accessor: 'comment',
            title: 'Описание',
            renderCell: (row) => (
                row.task ? row.task.about :
                    <TextField
                                id={`comment-${row.id}`}
                                className={styles.FieldSet__About}
                               type='textarea'
                               size='s'
                               value={row.comment}
                               onChange={({value}) => getTransportCreateListValueByUuid('comment', row.id, value)}
                    />
            )
        },
        // {
        //     accessor: 'task',
        //     title: 'Задача',
        //     width: 120,
        //     renderCell: ({task}) => (
        //         task &&
        //         <Layout className={styles.TransportType__Task}>
        //             <Text className={styles.TransportType__Task__Text}
        //                   view='link'
        //                   onClick={() => setTaskSidebar(task?.id)}><b>{task?.name}</b></Text>
        //         </Layout>
        //     )
        // },
        {
            accessor: 'id',
            title: 'Действия',
            renderCell: (row) => {
                if (row.status === 'CREATED') {
                    return (
                        <>
                            <Button iconLeft={IconCancel}
                                    onlyIcon
                                    view='clear'
                                    label='Отклонить'
                                    size='s'
                                    onClick={() => updateHandler(row, 'DECLINED')}
                            />
                            <Button iconLeft={IconWatch}
                                    onlyIcon
                                    view='clear'
                                    label='В ожидание'
                                    size='s'
                                    onClick={() => updateHandler(row, 'AWAITING')}
                            />
                            <Button iconLeft={IconCheck}
                                    onlyIcon
                                    view='clear'
                                    label='Отправить'
                                    size='s'
                                    onClick={() => submitHandler(row)}
                            />
                        </>
                    );
                }
                if (row.status === 'AWAITING') {
                    return (
                        <>
                            <Button iconLeft={IconCancel}
                                    onlyIcon
                                    view='clear'
                                    label='Отклонить'
                                    size='s'
                                    onClick={() => updateHandler(row, 'DECLINED')}
                            />
                            <Button iconLeft={IconCheck}
                                    onlyIcon
                                    view='clear'
                                    label='Отправить'
                                    size='s'
                                    onClick={() => submitHandler(row)}
                            />
                        </>
                    );
                }
                if (row.status === 'ASSIGNED') {
                    return (
                        <>
                            <Layout className={styles.TransportType__Task}>
                                <Text className={styles.TransportType__Task__Text}
                                      view='link'
                                      onClick={() => setTaskSidebar(row.task?.id)}><b>{row.task?.name}</b></Text>
                            </Layout>
                        </>
                    );
                }
            }
        },
    ];

    if (loading) {
        return (
            <Loading/>
        );
    }

    function setObj(key: string, value: any) {
        setSubmissionCreate({...submissionCreate, [key]: value});
    }

    return (
        <>
            <Toast alert={alert} setAlert={setAlert}/>
            <div className={styles.Breadcrumb__Container}>
                <Breadcrumbs items={breadCrumbs}
                             getItemLabel={(item: any) => item.label}
                             onlyIconRoot
                />

            </div>
            <Card className={styles.Content}>
                {
                    submission &&
                    <>
                        <Layout className={styles.FieldSet} direction='column'>
                            <Layout className={styles.Header__Container}>
                                {/*<Text as='h1'>Обработка заявки на {formatDate(submission.date)},*/}
                                {/*    бригада {submission.brigade.name} ({submission.brigade.company.name})</Text>*/}
                                <Button iconLeft={IconTrash}
                                        onlyIcon
                                        view='clear'
                                        onClick={() => setConfirmRemoveSubmissionOpen(true)}
                                />
                            </Layout>
                            <Layout className={styles.FieldSet__Row}>
                                <Text className={styles.FieldSet__Row__Label} view='secondary'>Номер заявки:</Text>
                                <Text>{submission.name}</Text>
                            </Layout>
                            <Layout className={styles.FieldSet__Row}>
                                <Text className={styles.FieldSet__Row__Label} view='secondary'>Бригада:</Text>
                                <Text>{submission.brigade?.name}</Text>
                            </Layout>
                            <Layout className={styles.FieldSet__Row}>
                                <Text className={styles.FieldSet__Row__Label} view='secondary'>Куст:</Text>
                                {submission.wells?.bush?.name === '_' ? (
                                    <>
                                        <Combobox
                                            id="bush"
                                            className={styles.FieldSet__Col}
                                            placeholder="Выберите куст"
                                            items={uniqueWells}
                                            onChange={({value}) => {
                                                const selectedBush = wells.find(item => item?.bush?.name === value);
                                                setSelectedBushId(selectedBush?.bush?.id ?? null);
                                                setSelectedBushName(value);
                                                if (submissionCreate?.wells_id && selectedBush?.id !== submissionCreate?.wells_id) {
                                                    setObj('wells_id', null);
                                                }
                                            }}
                                            value={selectedBushName}
                                            getItemLabel={item => item}
                                            getItemKey={item => item}
                                            required
                                        />
                                        <Combobox
                                            id="well"
                                            className={styles.FieldSet__Col}
                                            placeholder="Выберите скважину"
                                            items={selectedBushName ? filteredWells : []}
                                            onChange={({value}) => {
                                                setObj('wells_id', value?.id)
                                                setNewWellsID(value?.id)
                                                // console.log(wells.find(item => item.id === submissionCreate?.wells_id)?.id);
                                            }}
                                            value={wells.find(item => item.id === submissionCreate?.wells_id)}
                                            getItemLabel={item => item?.name}
                                            getItemKey={item => item.id!}
                                            required
                                            disabled={!selectedBushName}
                                        />

                                    </>) : <Text>{submission.wells?.bush?.name}</Text>}

                            </Layout>
                            {submission.wells?.bush?.name !== '_' && (
                                <Layout className={styles.FieldSet__Row}>
                                    <Text className={styles.FieldSet__Row__Label} view='secondary'>Скважина:</Text>
                                    <Text>{submission.wells?.name}</Text>
                                </Layout>)}
                            <Layout className={styles.FieldSet__Row}>
                                <Text className={styles.FieldSet__Row__Label} view='secondary'>Qн:</Text>
                                <Text>{submission?.oildeb}</Text>
                            </Layout>
                            {/*<Layout className={styles.FieldSet__Row}>*/}
                            {/*    <Text className={styles.FieldSet__Row__Label} view='secondary'>Статус:</Text>*/}
                            {/*    <Status status={submission.status}/>*/}
                            {/*</Layout>*/}
                            {/*<Layout className={styles.FieldSet__Row}>*/}
                            {/*    <Text className={styles.FieldSet__Row__Label} view='secondary'>Создан в:</Text>*/}
                            {/*    <Text>{formatDateTime(submission.created_at)}</Text>*/}
                            {/*</Layout>*/}
                            {/*<Layout className={styles.FieldSet__Row}>*/}
                            {/*    <Text className={styles.FieldSet__Row__Label} view='secondary'>Изменен в:</Text>*/}
                            {/*    <Text>{formatDateTime(submission.updated_at)}</Text>*/}
                            {/*</Layout>*/}
                            {/*<Layout className={styles.FieldSet__Row}>*/}
                            {/*    <Text className={styles.FieldSet__Row__Label} view='secondary'>Создал:</Text>*/}
                            {/*    <Text>{submission.created_by.fio}</Text>*/}
                            {/*</Layout>*/}

                        </Layout>
                        <Layout className={styles.FieldSet} direction='column'>
                            <Text as='h1'>Транспорт</Text>
                            <Table className={styles.Table}
                                   columns={columns}
                                   rows={transportTypes}
                                   borderBetweenRows
                            />
                        </Layout>
                    </>
                }
                <Sidebar isOpen={!!taskSidebar}
                         position='left'
                         size='2/3'
                         onEsc={() => setTaskSidebar(undefined)}
                         onClickOutside={() => setTaskSidebar(undefined)}
                >
                    <TaskView task_id={taskSidebar}/>
                </Sidebar>
                <Confirm isOpen={confirmRemoveSubmissionOpen}
                         onConfirm={() => removeSubmission()}
                         onClose={() => setConfirmRemoveSubmissionOpen(false)}
                         title={`Вы действительно хотите удалить заявку ${submission?.name}?`}
                />

            </Card>
        </>
    );
};

export {SubmissionProcess};
