import * as React from 'react';
import {FunctionComponent, useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {
    ApiError,
    Brigade,
    BrigadesService,
    LocationDetail,
    LocationsService,
    SubmissionCreate,
    SubmissionsService,
    SubmissionTransportTypeInlineCreate,
    TransportsService,
    TransportType, WellsSchema, WellsService, WorkTypeSchema, WorktypeService, BushSchema
} from "../../../api";
import {ProtectedRouter, PublicRouter} from "../../../Routers";
import {Form} from "../../../components/Form";
import styles from "./SubmissionForm.module.css";
import {Text} from "@consta/uikit/Text";
import {Layout} from "@consta/uikit/Layout";
import {DatePicker} from "@consta/uikit/DatePicker";
import {Select} from "@consta/uikit/Select";
import {Grid, GridItem} from "@consta/uikit/Grid";
import {TextField} from "@consta/uikit/TextField";
import {Button} from "@consta/uikit/Button";
import {IconAdd} from "@consta/uikit/IconAdd";
import {IconTrash} from "@consta/uikit/IconTrash";
import {getTimedeltaNumber} from "../../../utils/helpers";
import {IconArrowLeft} from "@consta/uikit/IconArrowLeft";
import {Card} from "@consta/uikit/Card";
import {Combobox} from "@consta/uikit/Combobox";


const SubmissionForm: FunctionComponent = () => {

    const [notification, setNotification] = useState<any>(undefined);
    const [submissionCreate, setSubmissionCreate] = useState<SubmissionCreate>({
        brigade_id: "",
        date: "",
        oildeb: "",
        // location_id: "",
        wells_id: "",
        transports: []
    });
    const bottomRef = useRef<null | HTMLDivElement>(null);
    const initialTransport = {type_id: '', count: 1, start: undefined, duration: undefined, comment: undefined};
    const [transports, setTransports] = useState<SubmissionTransportTypeInlineCreate[]>([initialTransport]);
    const [transportTypes, setTransportTypes] = useState<(TransportType & { disabled?: undefined })[]>([]);
    const [brigades, setBrigades] = useState<Brigade[]>([]);

    const [locations, setLocations] = useState<LocationDetail[]>([]);
    const navigate = useNavigate();
    const transportHeaders = ['Тип техники', 'Вид работ', 'Кол-во', 'Время начала', 'Продолжительность', 'Описание работ', ''];
    const [wells, setWells] = useState<WellsSchema[]>([]);
    const [bush, setBush] = useState<BushSchema[]>([]);
    const [worktype, setWorkType] = useState<WorkTypeSchema[]>([]);

    //
    const [selectedBushId, setSelectedBushId] = useState<string | null>(null);
    const filteredWells = wells.filter(item => item?.bush?.id === selectedBushId);
    const uniqueWells = [...new Set(wells.map(item => item?.bush?.name))];
    const [selectedBushName, setSelectedBushName] = useState<string | null>(null);

    // смещение часового пояса
    const now = new Date();
    const timezoneOffset = now.getTimezoneOffset() * 60000;
    const timezoneOffsetPlus5 = timezoneOffset + (5 * 60 * 60 * 1000); // добавляем 5 часов
    const minDate = new Date(now.getTime() - timezoneOffsetPlus5);
    // --

    const sortedBrigades = brigades.sort((a, b) => {
        // @ts-ignore
        return a.name.localeCompare(b.name);
    });

        const sortedTransportTypes = transportTypes.sort((a, b) => {
        // @ts-ignore
        return a.name.localeCompare(b.name);
    });


    const errorHandler = (err: ApiError) => {
        console.log(JSON.stringify(err));
        if (err.status === 404) {
            navigate(PublicRouter.NOT_FOUND);
            return;
        }
        if (err.status === 422) {
            if (err.body.detail) {
                console.log(err.body.detail);
                setNotification({title: err.statusText, status: 'alert'});
                return;
            }
        }
        setNotification({text: err.toString(), status: 'alert'});
    };

    useEffect(() => {
        document.title = 'Создать заявку | SMA';
    }, []);

    useEffect(() => {
        LocationsService.listLocations()
            .then((data) => setLocations([...data.items!]))
            .catch(errorHandler);
    }, []);

    useEffect(() => {
        BrigadesService.listBrigades()
            .then((data: Brigade[]) => setBrigades([...data]))
            .catch(errorHandler);
    }, []);

    useEffect(() => {
        TransportsService.listTransportTypes()
            .then((data: TransportType[]) => setTransportTypes([...data]))
            .catch(errorHandler);
    }, []);

    useEffect(() => {
        WellsService.listWells()
            .then((data) => setWells([...data]))
            .catch(errorHandler);
    }, []);

    useEffect(() => {
        WellsService.listBush()
            .then((data) => setBush([...data]))
            .catch(errorHandler);
    }, []);

    useEffect(() => {
        WorktypeService.listWorktype()
            .then((data) => setWorkType([...data]))
            .catch(errorHandler);

    }, []);

    useEffect(() => {
        setSelectedBushName(wells.find(item => item.id === submissionCreate?.wells_id)?.bush?.name ?? null);
    }, [submissionCreate?.wells_id]);


    function setObj(key: string, value: any) {
        setSubmissionCreate({...submissionCreate, [key]: value});
    }

    function setTransportsValue(name: string, index: string | number, value: any) {
        setTransports(prevTransports =>
            prevTransports.map((item: any, ind: number) =>
                ind === index ? {...item, [name]: value} : item
            )
        );
    }

    const submitHandler = () => {
        //  if (!task.transport?.reg_number) {
        //     setAlert({status: 'alert', text: 'Поле "ЕД.ТЕХНИКИ" является обязательным. Укажите гос.номер.'});
        //     setTechNumberStatus('alert')
        //     return;
        // } else {
        //     setTechNumberStatus('success')
        // }


        const payload = {
            ...submissionCreate,
            transports: transports.map((item) => ({...item, duration: getTimedeltaNumber(item.duration)}))
        };
        SubmissionsService.create(payload)
            .then((data: any) => {
                navigate(ProtectedRouter.SUBMISSION,
                    {state: {alert: {name: data?.name, action: 'created'}}});
            })
            .catch(errorHandler);
    };
    return (

        <Form
            mode='create'
            label='заявку на работы'
            onSubmit={submitHandler}
            notification={notification}
            showActions={true}
        >
            <Card className={styles.FieldSet} horizontalSpace='xl' border verticalSpace='2xl'>
                <Layout className={styles.FieldSet__Row}>
                    <Text className={styles.FieldSet__Row__Label} view='secondary'>Дата работ:</Text>
                    <DatePicker
                        type="date"
                        required
                        minDate={new Date()}
                        value={submissionCreate.date ? new Date(Date.parse(submissionCreate.date)) : undefined}
                        onChange={({value}) => {
                            if (value === null) {
                                return;
                            }
                            const date = value instanceof Date ? value : new Date(value);
                            date.setDate(date.getDate() + 1);
                            setObj('date', date.toJSON().slice(0, 10));
                        }}
                        status={!submissionCreate.date ? 'alert' : 'success'}
                    />
                </Layout>
                <Layout className={styles.FieldSet__Row}>
                    <Text className={styles.FieldSet__Row__Label} view='secondary'>Бригада:</Text>
                    <Combobox className={styles.FieldSet__Col}
                              placeholder="Выберите бригаду"
                              items={sortedBrigades}
                              onChange={({value}) => setObj('brigade_id', value?.id)}
                              value={brigades.find(item => item.id === submissionCreate?.brigade_id)}
                              getItemLabel={item => item?.name!}
                              getItemKey={item => item.id!}
                              status={!submissionCreate?.brigade_id ? 'alert' : 'success'}
                    />
                </Layout>
                <Layout className={styles.FieldSet__Row}>
                    <Text className={styles.FieldSet__Row__Label} view='secondary'>Локация:</Text>
                    <Combobox
                        className={styles.FieldSet__Col}
                        placeholder="Выберите куст"
                        items={uniqueWells}
                        onChange={({value}) => {
                            const selectedBush = wells.find(item => item?.bush?.name === value);
                            setSelectedBushId(selectedBush?.bush?.id ?? null);
                            setSelectedBushName(value);
                            if (submissionCreate?.wells_id && selectedBush?.id !== submissionCreate?.wells_id) {
                                setObj('wells_id', null);
                            }
                        }}
                        status={!selectedBushName ? 'alert' : 'success'}
                        value={selectedBushName}
                        getItemLabel={item => item}
                        getItemKey={item => item}
                        required
                    />
                    <Combobox
                        className={styles.FieldSet__Col}
                        placeholder="Выберите скважину"
                        items={selectedBushName ? filteredWells : []}
                        onChange={({value}) => setObj('wells_id', value?.id)}
                        value={wells.find(item => item.id === submissionCreate?.wells_id)}
                        getItemLabel={item => item?.name}
                        getItemKey={item => item.id!}
                        required
                        disabled={!selectedBushName}
                        status={!submissionCreate?.wells_id ? 'alert' : 'success'}
                    />

                </Layout>
                <Layout className={styles.FieldSet__Row}>
                    <Text className={styles.FieldSet__Row__Label} view='secondary'>Дебит нефти:</Text>
                    <TextField
                        type='textarea'
                        placeholder='Qн'
                        value={submissionCreate.oildeb}
                        onChange={({value}) => setObj('oildeb', value)}
                    />
                </Layout>

            </Card>
            <Card className={styles.FieldSet} horizontalSpace='2xl' border verticalSpace='2xl'>
                <Text as='h1'>Техника</Text>
                {/*<Table className={styles.Table} columns={columns} rows={transports}/>*/}
                <Grid className={`${styles.FieldSet__Row} ${styles.FieldSet__Header}`}
                      cols={transportHeaders.length}>
                    {
                        transportHeaders.map((item, index) => (
                            <GridItem key={index}>
                                <Text className={styles.FieldSet__Header__Text} as='h2' size='s'>{item}</Text>
                            </GridItem>
                        ))
                    }
                </Grid>
                {
                    transports.map((item, index) => (
                        <Grid className={styles.FieldSet__Row} cols={transportHeaders.length} key={index}>
                            <GridItem>
                                <Select
                                    placeholder="Выберите технику"
                                    items={sortedTransportTypes}
                                    onChange={({value}) => {
                                        setTransportsValue('type_id', index, value?.id);
                                    }}
                                    getItemLabel={item => item.name}
                                    getItemKey={item => item.id}
                                    value={transportTypes.find(type => type.id === item?.type_id)}
                                    status={!item?.type_id ? 'alert' : 'success'}
                                />
                            </GridItem>
                            <GridItem className={styles.FieldSet__WorkType}>
                                <Combobox
                                    placeholder="Вид работ"
                                    items={worktype.filter(type => type.type.id === item?.type_id)
                                .sort((a, b) => a.name.localeCompare(b.name))}
                                    onChange={({value}) => {

                                        const duration = value?.duration; // P0DT01H00M00S
                                        if (duration) {
                                            const hours = duration.toString().slice(4, 6); // 01
                                            const minutes = duration.toString().slice(7, 9); // 00
                                            const seconds = '00';
                                            const date = new Date();
                                            date.setHours(parseInt(hours));
                                            date.setMinutes(parseInt(minutes));
                                            date.setSeconds(parseInt(seconds));
                                            setTransportsValue('duration', index, date); // добавляем эту строку
                                        }
                                        setTransportsValue('worktype_id', index, value?.id);
                                    }}
                                    getItemLabel={item => item.name}
                                    getItemKey={item => item.id!}
                                    value={worktype.find(type => type.id === item?.worktype_id)}
                                    status={item?.worktype_id ? 'success' : 'alert'}
                                />
                            </GridItem>
                            <GridItem>
                                <TextField
                                    value={transports[index].count?.toString()}
                                    type='number'
                                    min={1}
                                    max={20}
                                    onChange={({value}) => setTransportsValue('count', index, value)}
                                />
                            </GridItem>
                            <GridItem>
                                <DatePicker
                                    type="date-time"
                                    multiplicityMinutes={10}
                                    multiplicitySeconds={0}
                                    minDate={minDate}
                                    format='dd.MM.yyyy HH:mm'
                                    placeholder='ДД.ММ.ГГГГ ЧЧ:ММ'
                                    value={transports[index].start ? new Date(Date.parse(transports[index].start!)) : undefined}
                                    onChange={({value}) => setTransportsValue('start', index, value)}
                                    status={!transports[index].start! ? 'alert' : 'success'}
                                />
                            </GridItem>
                            <GridItem>
                                <DatePicker
                                    type="time"
                                    multiplicityMinutes={10}
                                    multiplicitySeconds={0}
                                    format='HH:mm'
                                    placeholder='ЧЧ:ММ'
                                    caption={!transports[index].duration! ? 'выберите из списка' : ''}
                                    value={transports[index].duration ? new Date(transports[index].duration!) : undefined}
                                    onChange={({value}) => {setTransportsValue('duration', index, value)}}
                                    status={!transports[index].duration! ? 'alert' : 'success'}
                                />
                            </GridItem>
                            <GridItem>
                                <TextField
                                    type='textarea'
                                    placeholder='Описание работ'
                                    value={transports[index].comment}
                                    onChange={({value}) => setTransportsValue('comment', index, value)}
                                />
                            </GridItem>
                            <GridItem>
                                <Button type='button' view='clear'
                                        onlyIcon
                                        iconLeft={IconTrash}
                                        onClick={() => setTransports([...transports.filter((item, ind) => ind !== index)])}
                                />
                            </GridItem>

                        </Grid>
                    ))
                }
                <Grid cols={1} ref={bottomRef}>
                    <GridItem>
                        <Button
                            label='Добавить технику'
                                view='clear'
                                iconLeft={IconAdd}
                                onClick={() => {
                                    setTransports([...transports, initialTransport]);
                                    bottomRef.current?.scrollIntoView();
                                }}
                        />
                    </GridItem>
                </Grid>
            </Card>
        </Form>
    );
};

export {SubmissionForm};
