/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { User } from '../models/User';
import type { UserCreate } from '../models/UserCreate';
import type { UserDetail } from '../models/UserDetail';
import type { UserUpdate } from '../models/UserUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsersService {

    /**
     * Get User
     * @param userId 
     * @returns UserDetail OK
     * @throws ApiError
     */
    public static getUser(
userId: string,
): CancelablePromise<UserDetail> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/users/{user_id}',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * Delete User
     * @param userId 
     * @returns any OK
     * @throws ApiError
     */
    public static deleteUser(
userId: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/users/{user_id}',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * Get Me
     * @returns UserDetail OK
     * @throws ApiError
     */
    public static getMe(): CancelablePromise<UserDetail> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/users/me/',
        });
    }

    /**
     * Get User List
     * @returns UserDetail OK
     * @throws ApiError
     */
    public static getUserList(): CancelablePromise<Array<UserDetail>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/users/',
        });
    }

    /**
     * Create User
     * @param requestBody 
     * @returns User Created
     * @throws ApiError
     */
    public static createUser(
requestBody: UserCreate,
): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/users/register/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update User
     * @param userId 
     * @param requestBody 
     * @returns User OK
     * @throws ApiError
     */
    public static updateUser(
userId: string,
requestBody: UserUpdate,
): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/users/update/{user_id}',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
