import * as React from 'react';
import {Layout} from "@consta/uikit/Layout";
import styles from './ListPage.module.css';
import {useSearchParams} from "react-router-dom";
import {Breadcrumbs} from "@consta/uikit/Breadcrumbs";
import {Card} from "@consta/uikit/Card";
import {Pagination} from "@consta/uikit/Pagination";
import {FilterPageEnum} from "../../utils/enum";

type ListPageProps = {
    children: React.ReactNode;
    breadCrumbs?: any[];
    page?: number;
    setPage?: (page: number) => void;
    totalPages?: number;
}

const ListPage: React.FC<ListPageProps> = ({
                                               children,
                                               breadCrumbs,
                                               page,
                                               setPage,
                                               totalPages
                                           }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const onChange = (value?: number) => {
        if (setPage) {
            if (value) {
                setPage(value + 1);
                searchParams.set(FilterPageEnum.Page, String(value));
            } else {
                setPage(1);
                searchParams.delete(FilterPageEnum.Page);
            }
            setSearchParams(searchParams);
        }
    };
    return (
        <>
            {
                breadCrumbs &&
                <div className={styles.Breadcrumb__Container}>
                    <Breadcrumbs items={breadCrumbs}
                                 getItemLabel={(item: any) => item.label}
                                 onlyIconRoot
                    />
                </div>
            }
            <Card className={styles.Content}>
                <Layout className={styles.Container}>
                    {children}
                </Layout>
            </Card>
            {
                (page && setPage && totalPages) ?
                    <Layout className={styles.Footer}>
                        <Pagination className={styles.Pagination}
                                    currentPage={parseInt(page.toString()) - 1}
                                    totalPages={totalPages}
                                    onChange={onChange}
                                    minified
                        />
                    </Layout> : undefined
            }
        </>
    );
};

export {ListPage};
