import {Button} from "@consta/uikit/Button";
import {FunctionComponent} from "react";
import {useNavigate} from "react-router-dom";
import styles from './AccessDenied.module.css';
import {Responses403} from "@consta/uikit/Responses403";


export const AccessDenied: FunctionComponent = () => {
    const navigate = useNavigate();
    return (
        <>
            <Responses403
                className={styles.Error}
                title="Недостаточно прав"
                description=" "
                actions={
                    <Button view="ghost" label="Вернуться назад" onClick={() => navigate(-1)}/>
                }
            />
        </>
    );
}